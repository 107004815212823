import {Link, Outlet, useLocation} from "react-router-dom";
import {Grid, Paper, Tab, Tabs} from "@mui/material";
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import EditAttributesOutlinedIcon
  from '@mui/icons-material/EditAttributesOutlined';
import ManageAccountsOutlinedIcon
  from '@mui/icons-material/ManageAccountsOutlined';
import Page from "../components/Page";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

export default function Admin() {
  const location = useLocation();

  return (
      <Page title={"LabPort | Admin Settings"}>
        <Grid container direction={"column"} spacing={1}>
          <Grid item>
            <Paper>
              <Tabs
                  value={location.pathname}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  orientation="horizontal"
              >
                <Tab label="Create Facility"
                     component={Link}
                     icon={<AddBusinessOutlinedIcon/>}
                     to={"/Admin/CreateFacility"}
                     value={"/Admin/CreateFacility"}/>
                <Tab label="Create User"
                     component={Link}
                     icon={<PersonAddAltOutlinedIcon/>}
                     to={"/Admin/CreateUser"}
                     value={"/Admin/CreateUser"}/>
                <Tab label="Manage Users Access"
                     component={Link}
                     icon={<ManageAccountsOutlinedIcon/>}
                     to={"/Admin/UserAccess"}
                     value={"/Admin/UserAccess"}/>
                <Tab label="Edit Facility"
                     component={Link}
                     icon={<EditAttributesOutlinedIcon/>}
                     to={"/Admin/EditFacility"}
                     value={"/Admin/EditFacility"}/>
              </Tabs>
            </Paper>
          </Grid>
          <Grid item>
            <Paper
                sx={{
                  p: 1,
                }}>
              <Outlet/>
            </Paper>
          </Grid>
        </Grid>
      </Page>
  )
}
