export const firestoreDataConverter = {
  toFirestore(data) {
    delete data['id'];
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data['id'] = snapshot.id;
    return data;
  }
};
