import {Link, Outlet, useNavigate} from 'react-router-dom';
import {styled} from '@mui/material';
import Logo from '../components/Logo';
import {useEffect} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../components/firebaseConfig";

const HeaderStyle = styled('header')(({theme}) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

export default function LogoOnlyLayout() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      navigate("/dashboard");
    }
  }, [loading, navigate, user]);

  return (
      <>
        <HeaderStyle>
          <Link to="/">
            <Logo/>
          </Link>
        </HeaderStyle>
        <Outlet/>
      </>
  );
}
