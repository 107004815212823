import {useCallback, useState} from 'react';
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Switch,
  Typography
} from "@mui/material";
import {
  addDoc,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where
} from "firebase/firestore";
import {useAuthState} from "react-firebase-hooks/auth";
import {CSVReader} from 'react-papaparse';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {auth, database} from "../components/firebaseConfig";
import {resultNotify} from "../components/notify";
import Page from "../components/Page";

export default function UploadResults() {
  const [currentUser] = useAuthState(auth);

  const [loading, setLoading] = useState(false);

  const [emailNotification, setEmailNotification] = useState(true);
  const [textNotification, setTextNotification] = useState(true);

  const [resultsUploaded, setResultsUploaded] = useState([]);
  const [resultsFailed, setResultsFailed] = useState([]);
  const [resultsFailedCompleted, setResultsFailedCompleted] = useState([]);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const sweetAlert = withReactContent(Swal);

  const handleError = (err) => {
    console.log(err);
  };

  const metaFieldsErrorMessageText = () => <>Please use the template provided.
    <br/><br/>The column headers have to be 'Result' and 'Barcode'.<br/>
    The headers are case sensitive.</>

  const handleOnFileLoad = (results, retry) => {
    setLoading(true);
    if (results.meta.fields.length !== 2) {
      sweetAlert.fire({
        title: <p>CSV File Does Not Match The Template</p>,
        icon: "error",
        html: metaFieldsErrorMessageText,
      }).then();
      setLoading(false);
      return;
    }
    if (results.errors.length > 0) {
      let errorText = "";
      for (let [i, someError] of results.errors.entries()) {
        errorText += `${i + 1}. ${someError.message} at line ${someError.row
        + 2}.<br/>`
      }
      errorText += `Your upload has been cancelled, please try again.`;
      sweetAlert.fire({
        title: <p>Your file has {results.errors.length} Error(s).</p>,
        icon: "error",
        html: errorText,
      }).then();
      setLoading(false);
      return;
    }
    if (!(results.meta.fields.includes("Result")
        && results.meta.fields.includes("Barcode"))) {
      sweetAlert.fire({
        title: <>Missing the 'Result' and/or 'Barcode' column(s)</>,
        icon: "error",
        html: metaFieldsErrorMessageText,
      }).then();
      setLoading(false);
      return;
    }

    sweetAlert.fire({
      title: <p> {results.data.length} patient result(s) found in this
        file.</p>,
      icon: "warning",
      html: "If you upload results twice, the old results will be overridden.",
      confirmButtonText: "Upload Results",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        if (retry)
            //clear out old results
        {
          setResultsUploaded([]);
        }
        setResultsFailed([]);
        setResultsFailedCompleted([])

        let promises = [];

        results.data.map(async (result) => {
              promises.push(new Promise((resolve, reject) => {
                getDocs(query(database.reqForms,
                    where('sample.barcode', '==',
                        retry ? result['Barcode'].toLowerCase()
                            : result['Barcode'].toUpperCase())
                ))
                .then(querySnapshot => {
                  if (querySnapshot.empty) {
                    let newFailed = resultsFailed;
                    newFailed.push(retry ? result['Barcode'].toLowerCase()
                        : result['Barcode'].toUpperCase())
                    setResultsFailed(newFailed)

                    let newFailedCompleted = resultsFailedCompleted;
                    newFailedCompleted.push({
                      "Barcode": result['Barcode'],
                      "Result": result['Result'].toLowerCase().includes("covid")
                          ? "Positive" : result['Result']
                    })
                    setResultsFailedCompleted(newFailedCompleted)
                    reject(result['Barcode']);
                  }

                  querySnapshot.forEach((reqForm) => {
                    setDoc(doc(database.reqForms, reqForm.id), {
                      author: {
                        "resultsBy": currentUser.uid,
                        "results_timestamp": serverTimestamp()
                      },
                      "result": result['Result'].toLowerCase().includes("covid")
                          ? "Positive" : result['Result'],
                    }, {merge: true}).then(async () => {

                      let notificationPayload = {
                        reqFormid: reqForm.id,
                        collectionTime: reqForm.data().sample.collectionTime.seconds,
                        fname: reqForm.data().patient.fname
                      };

                      if (textNotification) {
                        notificationPayload.phone = reqForm.data().patient.phone;
                      }
                      if (emailNotification) {
                        notificationPayload.email = reqForm.data().patient.email;
                      }

                      //send notifications
                      await resultNotify(notificationPayload).then(() => {

                      }).catch(e => {

                        sweetAlert.fire({
                          title: <p>{e}</p>,
                          text: `could not notify barcode:${result['Barcode']}. Try a manual notification?`,
                          icon: "error",
                          toast: true,
                          position: 'bottom-end',
                          timer: 3000,
                          showCancelButton: false,
                          showConfirmButton: false,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter',
                                Swal.stopTimer);
                            toast.addEventListener('mouseleave',
                                Swal.resumeTimer);
                          }
                        })
                      });
                      resolve(result['Barcode'])
                      let newResults = resultsUploaded;
                      newResults.push(retry ? result['Barcode'].toLowerCase()
                          : result['Barcode'].toUpperCase())
                      setResultsUploaded([])
                      setResultsUploaded(newResults)
                      //resolve promise
                    }).catch(e => {
                      reject({e, errorBarcode: result['Barcode']})
                      let newFailed = resultsFailed;
                      newFailed.push(result['Barcode'])
                      setResultsFailed(newFailed)
                    });
                  });
                });
              }));
            }
        );
        Promise.allSettled(promises).then(() => {
          sendReport();
          setLoading(false);
          forceUpdate();
        });
      } else {
        setLoading(false);
      }
    });

    // verify upload
    // verify consistency and empty cells
    // create batch
    // commit batch
    // display success message
  };

  function sendReport(emails) {
    //grab all stats and email support
    let payload = {
      "message": {
        "subject": `Result Report🧪: ${new Date().toLocaleString()}`,
        "text": currentUser.email
            + " uploaded results to LabPort. Text notifications were "
            + (textNotification ? "on" : "off")
            + ". Email notifications were"
            + (emailNotification ? "on" : "off")
            + ". \nTotal result uploads:"
            + resultsUploaded.length + resultsFailed.length
            + "\nTotal successfully uploaded: "
            + resultsUploaded.length
            + "\nTotal failed uploads: "
            + resultsFailed.length
            + "\n\nDetails:\n"
            + (resultsUploaded.length > 0 ? "Uploaded: "
                + resultsUploaded.toString() + "\n" : "")
            + (resultsFailed.length > 0 ? "Failed: "
                + resultsFailed.toString() + "\n" : "")
            + "\nThis message was sent from LabPort.app",
      }
    };
    if (typeof emails === "undefined") {
      payload.to = ["support@labport.app"];
    } else {
      payload.to = emails;
      payload.bcc = ["support@labport.app"]
    }
    addDoc(database.sendMail, payload)
    .then(() => {
      if (typeof emails !== "undefined") {
        sweetAlert.fire({
          title: <p>Email report sent!</p>,
          text: "A copy will be CC'd to you as well.",
          icon: "success",
          toast: true,
          position: 'bottom-end',
          timer: 7000,
          showCancelButton: false,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        }).then(() => {
        })
      }
    }).catch(e => {
      setLoading(false);
      sweetAlert.fire({
        title: <p>Could not email report. Try to send it manually.</p>,
        text: e,
        icon: "error",
      }).then();
    });
  }

  return (
      <Page title={"LabPort | Upload Results"}>
        {loading && <LinearProgress/>}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Upload Patient Results
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row={true}>
              <FormControlLabel
                  control={
                    <Switch
                        checked={emailNotification}
                        onChange={(e) => {
                          setEmailNotification(e.target.checked)
                        }}
                        name="Send Email Notifications"
                        color="primary"
                    />
                  }
                  label="Send Email Notifications"
              />
              <FormControlLabel
                  control={
                    <Switch
                        checked={textNotification}
                        onChange={(e) => {
                          setTextNotification(e.target.checked)
                        }}
                        name="Send Text Notifications"
                        color="primary"
                    />
                  }
                  label="Send Text Notifications"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography color='textSecondary' style={{fontSize: "1rem"}}>
              Csv files should contain 2 columns.
              One for the barcodes, one for the result.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CSVReader
                addRemoveButton
                accept='text/csv, .csv, application/vnd.ms-excel'
                onError={handleError}
                style={{
                  fileNameInfo: {
                    color: "#d44e5c",
                  },
                  fileSizeInfo: {
                    color: "#d44e5c",
                  }
                }}
                config={{
                  error: handleError,
                  header: true,
                  complete: (results, file) => handleOnFileLoad(results, file)
                }}
            >
              <Typography align="center">
                Drop CSV file here or click to upload.
              </Typography>
            </CSVReader>
          </Grid>
          {resultsUploaded.length > 0 ?
              <Grid item xs={12}>
                Uploaded: {resultsUploaded.toString()}
                <br/>
                Total Uploaded: {resultsUploaded.length}
              </Grid>
              :
              ""
          }
          {resultsFailed.length > 0 ?
              <Grid item xs={12}>
                Failed: {resultsFailed.toString()}
                <br/>
                Total Failed: {resultsFailed.length}
              </Grid>
              :
              ""
          }
          {(resultsUploaded.length > 0 || resultsFailed.length > 0) ?
              <Grid item xs={12}>
                <Button variant={'outlined'} color={"secondary"}
                        onClick={() => {
                          setResultsUploaded([]);
                          setResultsFailed([]);
                          setResultsFailedCompleted([])
                        }}>Clear Log
                </Button>
                <Button style={{marginLeft: "1rem"}}
                        variant={'outlined'} color={"primary"}
                        onClick={() => {
                          const payload = {
                            "data": resultsFailedCompleted,
                            "errors": [],
                            "meta": {
                              "fields": [
                                "Barcode",
                                "Result"
                              ]
                            }
                          }
                          handleOnFileLoad(payload, true)
                        }}
                >
                  Retry Failed w/ lowercase
                </Button>
              </Grid>
              :
              ""
          }
        </Grid>
      </Page>
  )

}
