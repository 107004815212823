import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {doc, getDoc} from "firebase/firestore";
import {useSnackbar} from "notistack";
import {database} from "../components/firebaseConfig";
import {usePatient} from "../contexts/PatientProvider";
import {defaultPatientTypes} from "../const/defaultPatientTypes";
import Page from "../components/Page";
import SelfReportForm from "../components/SelfReportForm";

export default function SelfReport() {
  const {currentSelfReport, changeSelfReport} = usePatient();
  const [currentReportData, setCurrentReportData] = useState(null);
  const [facilityData, setFacilityData] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const getReportFromId = (id) => {
    getDoc(doc(database.selfReports, id)).then(refForm => {
      getDoc(doc(database.facilities, refForm.data().facility.id))
      .then(facility => {
        setFacilityData({
          patientCategories: defaultPatientTypes,
          ...facility.data(),
        });
        setCurrentReportData({
          id: id,
          ...refForm.data(),
        });
        changeSelfReport(null);
      });
    });
  };

  useEffect(() => {
    if (currentSelfReport) {
      getReportFromId(currentSelfReport);
    } else {
      enqueueSnackbar('Could not load self report to view.'
          + ' Redirecting...',
          {variant: 'warning'});
      navigate("/SelfReports");
    }
  }, []);

  return (
      <Page title={"LabPort | Self Report"}>
        {currentReportData ?
            <SelfReportForm
                facilityData={facilityData}
                currentReportData={currentReportData}/>
            : <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: "center"
            }}>
              <CircularProgress/>
            </Box>
        }
      </Page>
  );
}
