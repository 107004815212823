import {Box, Button, InputLabel, Paper} from "@mui/material";
import SignaturePad from "react-signature-pad-wrapper";
import {forwardRef} from "react";

export const CustomSignaturePad = forwardRef((props, ref) => (
    <Paper
        style={{
          padding: "15px",
        }}>
      <InputLabel>Signature</InputLabel>
      <Box
          sx={{
            borderRadius: "10px",
            margin: "10px",
            bgcolor: "grey.500",
          }}>
        <SignaturePad
            ref={ref}
            height={80}
            redrawOnResize={true}
            options={{penColor: 'rgb(40,40,40)'}}/>
      </Box>
      <Button
          style={{
            backgroundColor: "inherit",
            marginTop: "3px",
          }}
          onClick={() => {
            ref.current.clear();
          }}
          variant={"outlined"}
          className={"fixButton"}>
        Clear
      </Button>
    </Paper>
));
