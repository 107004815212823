import {useEffect, useState} from "react";

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (error) {
    return false;
  }
  return true;
}

export default function useStorage({key, initialValue, type, validator}) {
  const [value, setValue] = useState(() => {
    let value = window.localStorage.getItem(key);
    if (type === 'boolean') {
      if (value === "false" || value === "true") {
        value = (value === 'true');
      } else {
        value = null;
      }
    } else if (isJsonString(value)) {
      value = JSON.parse(value);
      if (type === 'string') {
        if (typeof value !== 'string') {
          value = null;
        }
      } else if (type === 'object') {
        if (typeof value !== 'object') {
          value = null;
        }
      } else if (type === 'array') {
        if (typeof value !== 'object') {
          value = null;
        }
        if (!Array.isArray(value)) {
          value = null;
        }
      }
    } else {
      value = null;
    }
    return value === null ? initialValue : value;
  });

  useEffect(() => {
    function update({key: k, newValue}) {
      if (k === key) {
        if (validator && !validator(newValue)) {
          setValue(initialValue);
          return;
        }
        if ((type === "object" || type === "array")
            && typeof newValue != "object") {
          setValue(JSON.parse(newValue))
        } else {
          setValue(newValue);
        }
      }
    }

    window.addEventListener("storage", update);
    return () => window.removeEventListener("storage", update);
  }, [initialValue, key, type, validator]);

  useEffect(() => {
    if (type === 'boolean') {
      localStorage.setItem(key, value);
    } else {
      if (typeof value !== "string") {
        localStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, value);
      }
    }
  }, [value]);

  return [value, setValue];
}
