export const rejectReasons = [
  {
    name: "insufficient",
    message: "Uploaded image blurry/not in frame.",
    title: "Too Blurry or Unreadable",
  },
  {
    name: "unsuitable",
    message: "Uploaded image did not meet verification requirements.",
    title: "Unsuitable/Inappropriate",
  },
  {
    name: "incorrect",
    message: "Uploaded image does not match self-report.",
    title: "Incorrect Result",
  },
  {
    name: "expired",
    message: "Test older than the allotted time frame of 12 hours. New test required.",
    title: "Image Too Old",
  },
];
