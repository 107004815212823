import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebaseConfig";
import {Box, CircularProgress} from "@mui/material";

export default function RequireAuth() {
  const [user, loading] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      alignItems: "center"
    }}>
      <CircularProgress/>
    </Box>
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet/>;
}