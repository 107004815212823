export const deliveryTypes = [
  {
    value: "immediate",
    name: "Immediate",
  },
  {
    value: "daily",
    name: "Daily Digest",
  },
];

export const dailyTime = [
  {
    value: "morning",
    name: "AM",
  },
  {
    value: "evening",
    name: "PM",
  },
];
