import {database} from "./firebaseConfig";
import {addDoc} from "firebase/firestore";
import {auditResults} from "../const/auditResults";

export const resultNotify = (payload) => {
  //payload = {phone:"1231231234",email:"1231231234",reqFormid:"fjFasbF19024924924891"}
  return new Promise(async (resolve, reject) => {
    if (!payload.reqFormid) {
      reject("Missing req form id");
      return;
    }
    let errors = [];
    if (Object.keys(payload).includes("email")) {
      try {
        await addDoc(database.sendMail, {
          "to": [payload.email],
          "replyTo": ["support@labport.com"],
          template: {
            name: "notificationCTA",
            data: {
              subject: "LabPort🧪: Your results are ready!",
              title: "Your Test Results Are Here!",
              body: "By getting tested, you are helping to slow the spread of COVID-19. Thank you for doing your part in keeping yourself and your community healthy and safe.",
              cta: "View Result",
              ctaLink: `https://results.labport.app/?r=${payload.reqFormid}`,
            }
          }
        });
      } catch (e) {
        errors.push(e);
      }
    }
    if (Object.keys(payload).includes("phone")) {
      try {
        await addDoc(database.sendSMS, {
          "to": payload.phone,
          "body":
              `LabPort🧪: ${payload.fname}, your ${new Date(
                  payload.collectionTime
                  * 1000).toLocaleString()} results are ready! Please visit https://results.labport.app/?r=${payload.reqFormid} to view it!`
        });
      } catch (e) {
        errors.push(e);
      }
    }
    if (errors.length === 0) {
      const message = {
        "email": "Email notification scheduled.",
        "sms": "SMS notification scheduled.",
        "email&sms": "Email & SMS notifications scheduled."
      };
      if (Object.keys(payload).includes("email") && Object.keys(
          payload).includes("phone")) {
        resolve(message["email&sms"]);
      } else if (Object.keys(payload).includes("email") && !Object.keys(
          payload).includes("phone")) {
        resolve(message["email"]);
      } else if (Object.keys(payload).includes("phone") && !Object.keys(
          payload).includes("email")) {
        resolve(message["phone"]);
      } else if (!Object.keys(payload).includes("phone") && !Object.keys(
          payload).includes("email")) {
        resolve("");
      }
    } else {
      reject(JSON.stringify(errors));
    }
  })
};

export const selfReportNotify = (payload) => {
  const {
    selfReportId,
    firstName,
    result,
    rejectMessage,
    uploadTime,
    email,
    phone
  } = payload;
  return new Promise(async (resolve, reject) => {
    if (!selfReportId) {
      reject("Missing Self Report Id");
      return;
    }
    let errors = [];
    let data;
    if (result === auditResults.approve) {
      data = {
        subject: "LabPort🧪: Your self report was approved!",
        title: "Your self report is complete!",
        body: "By getting tested, you are helping to slow the spread of COVID-19. "
            + "Thank you for doing your part in keeping yourself and your community healthy and safe.",
      };
    }
    if (result === auditResults.reject) {
      data = {
        subject: "LabPort🧪: Your self report has been rejected",
        title: "Your self report has been rejected",
        body: "Unfortunately, your self report has been rejected. The reason for the rejection: "
            + rejectMessage,
      };
    }
    if (email) {
      try {
        await addDoc(database.sendMail, {
          "to": [email],
          "replyTo": ["support@labport.com"],
          template: {
            name: "notification",
            data: data,
          }
        });
      } catch (e) {
        errors.push(e);
      }
    }
    if (phone) {
      try {
        await addDoc(database.sendSMS, {
          "to": phone,
          "body":
              "LabPort🧪: " + firstName + ", your "
              + uploadTime.toLocaleString()
              + " result has been " +
              ((result === "Approved") ? "approved!"
                  : "rejected. " + rejectMessage),
        });
      } catch (e) {
        errors.push(e);
      }
    }
    //return resolve message
    if (errors.length > 0) {
      reject(JSON.stringify(errors));
    }
    const message = {
      "email": "Email notification scheduled.",
      "sms": "SMS notification scheduled.",
      "email&sms": "Email & SMS notifications scheduled.",
      "neither": "Audit successfully uploaded!",
    };
    if (email) {
      if (phone) {
        resolve(message["email&sms"]);
      } else {
        resolve(message["email"]);
      }
    } else if (phone) {
      resolve(message["sms"]);
    } else {
      resolve(message["neither"]);
    }
  });
};
