import {Link, Link as RouterLink} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../components/firebaseConfig";
import {useRef, useState} from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {alpha} from '@mui/material/styles';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Typography
} from '@mui/material';
import MenuPopover from './MenuPopover';
import {useAuth} from "../../contexts/CustomAuthProvider";

export default function AccountPopover(props) {
  const {setToolbarTitle} = props;
  const [currentUser] = useAuthState(auth);
  const {userData, logout} = useAuth();

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: HomeOutlinedIcon,
      linkTo: '/',
      onClick: () => setToolbarTitle("Dashboard"),
    },
    {
      label: 'Profile',
      icon: PersonOutlinedIcon,
      linkTo: '/EditAccount',
      onClick: () => setToolbarTitle("Edit Account"),
    },
    // {
    //   label: 'Settings',
    //   icon: SettingsOutlinedIcon,
    //   linkTo: '#',
    // }
  ];

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
      <>
        <Link className="disableDecoration" to="/EditAccount"
              onClick={() => {
                setToolbarTitle("Edit Account");
              }} style={{textDecoration: 'none'}}>
        </Link>
        <IconButton
            ref={anchorRef}
            onClick={handleOpen}
            sx={{
              padding: 0,
              width: 44,
              height: 44,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                }
              })
            }}
        >
          <Avatar alt="User Picture" src={currentUser?.photoURL}>
            {!currentUser?.photoURL && currentUser?.email?.charAt(0)}
          </Avatar>
        </IconButton>

        <MenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            sx={{width: 220}}
        >
          <Box sx={{my: 1.5, px: 2.5}}>
            <Typography variant="subtitle1" noWrap>
              {currentUser?.displayName ?? currentUser?.email}
            </Typography>
            <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
              {currentUser?.displayName ? currentUser?.email
                  : userData?.roles?.role}
            </Typography>
          </Box>

          <Divider sx={{my: 1}}/>

          {MENU_OPTIONS.map((option) => (
              <MenuItem
                  key={option.label}
                  to={option.linkTo}
                  component={RouterLink}
                  onClick={handleClose && option.onClick}
                  sx={{typography: 'body2', py: 1, px: 2.5}}
              >
                <Box
                    component={option.icon}
                    sx={{
                      mr: 2,
                      width: 24,
                      height: 24
                    }}
                />
                {option.label}
              </MenuItem>
          ))}
          <Box sx={{p: 2, pt: 1.5}}>
            <Button
                fullWidth
                color="inherit"
                variant="outlined"
                onClick={() => logout()}
            >
              Logout
            </Button>
          </Box>
        </MenuPopover>
      </>
  );
}
