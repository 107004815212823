import {useState} from 'react';
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import Page from "../components/Page";

export default function CreateFacility() {

  const fixedOptions = [
    {"name": "Physician 1", NPI: "8284414441"},
    {"name": "Physician 2", NPI: "82842414441"},
    {"name": "Physician 3", NPI: "82844144341"}
  ];
  const access = [
    {"email": "email1@gmail.com", id: "8284414441"},
    {"email": "email2@gmail.com", id: "82842414441"},
    {"email": "email3@gmail.com", id: "82844144341"}
  ];
  const [value, setValue] = useState([...fixedOptions]);

  return (
      <Page title={"LabPort | Create Facility"}>
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <TextField
                variant={"filled"}
                autoFocus
                label={"Facility Name"}
                fullWidth
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <MuiPhoneNumber
                label={"Facility Phone Number"}
                variant={"filled"}
                defaultCountry={'us'}
                disableAreaCodes
                fullWidth
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
                variant={"filled"}
                label={"Facility Address"}
                fullWidth
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
                variant={"filled"}
                label={"Facility NPI"}
                fullWidth
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Autocomplete
                multiple
                onChange={(event, newValue) => {
                  setValue([
                    ...fixedOptions,
                    ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1),
                  ]);
                }}
                options={fixedOptions}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            color={"primary"}
                            avatar={<Avatar>{option.name[0]}</Avatar>}
                            label={option.name}
                            {...getTagProps({index})}
                            disabled={fixedOptions.indexOf(option) !== -1}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label="Physicians"/>
                )}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Autocomplete
                multiple
                onChange={(event, newValue) => {
                  setValue([
                    ...fixedOptions,
                    ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1),
                  ]);
                }}
                options={access}
                getOptionLabel={(option) => option.email}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            sx={{backgroundColor: theme => theme.palette.warning}}
                            avatar={<Avatar>{option.email[0]}</Avatar>}
                            label={option.email}
                            {...getTagProps({index})}
                            disabled={access.indexOf(option) !== -1}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label="Facility Access"/>
                )}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Autocomplete
                options={["Employer", "Normal"]}
                renderInput={(params) => (
                    <TextField {...params} label="Type"/>
                )}
            />
          </Grid>
          <Divider/>
          <Grid item lg={12} xs={12}>
            <Button variant={"outlined"}>Coming Soon</Button>
          </Grid>
        </Grid>
      </Page>
  );
}
