import {IconButton, Stack, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {Draggable} from "react-beautiful-dnd";
import {Controller} from "react-hook-form";

const PhysicianListItem = (props) => {
  const {id, control, name, index, update, remove, trigger} = props;

  const onBlurEvent = ({label}) => {
    trigger([
          `${name}.${index}.name`,
          `${name}.${index}.NPI`,
          `${name}.${index}.contact`,
        ],
        {shouldFocus: false})
    .then((result) => {
      if (result) {
        update({
          name: name,
          label: label,
        });
      }
    });
  }

  return (
      <Draggable
          draggableId={id}
          index={index}
      >
        {(provided) => (
            <Stack
                ref={provided.innerRef}
                {...provided.draggableProps}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >
              <div {...provided.dragHandleProps}>
                <DragIndicatorIcon/>
              </div>
              <Controller
                  name={`${name}.${index}.name`}
                  render={({field, fieldState}) => (
                      <TextField
                          {...field}
                          label={"Physician Name"}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          onBlur={(event) => {
                            onBlurEvent({
                              label: "Physician Name",
                            });
                          }}
                          autoComplete={"new-password"}
                          fullWidth
                          required
                      />
                  )}
                  rules={{required: true}}
                  control={control}
              />
              <Controller
                  name={`${name}.${index}.NPI`}
                  render={({field, fieldState}) => (
                      <TextField
                          {...field}
                          label={"Physician NPI"}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          onBlur={(event) => {
                            onBlurEvent({
                              label: "Physician NPI",
                            });
                          }}
                          autoComplete={"new-password"}
                          fullWidth
                          required
                      />
                  )}
                  rules={{required: true}}
                  control={control}
              />
              <Controller
                  name={`${name}.${index}.contact`}
                  render={({field, fieldState}) => (
                      <TextField
                          {...field}
                          label={"Physician Contact Info"}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error?.message}
                          onBlur={(event) => {
                            onBlurEvent({
                              label: "Physician Contact Info",
                            });
                          }}
                          autoComplete={"new-password"}
                          fullWidth
                          required
                      />
                  )}
                  rules={{required: true}}
                  control={control}
              />
              <IconButton
                  size={"large"}
                  onClick={() => remove(index)}
                  color={"error"}
              >
                <DeleteIcon/>
              </IconButton>
            </Stack>
        )}
      </Draggable>
  );
};

export default PhysicianListItem;
