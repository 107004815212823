import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material';
import useCustomTheme from "../hooks/useCustomTheme";

export default function ThemeConfig(props) {
  const {children} = props;

  const theme = useCustomTheme();

  return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
  );
}
