import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {doc, getDoc} from "firebase/firestore";
import {useSnackbar} from "notistack";
import {database} from "../components/firebaseConfig";
import {usePatient} from "../contexts/PatientProvider";
import {defaultPatientTypes} from "../const/defaultPatientTypes";
import EditReqForm from "../components/EditReqForm";
import Page from "../components/Page";

export default function ReqForm() {
  const {currentReport, changeReport} = usePatient();
  const [currentReportData, setCurrentReportData] = useState(null);
  const [facilityData, setFacilityData] = useState(null);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const getReportFromId = (id) => {
    getDoc(doc(database.reqForms, id)).then(refForm => {
      getDoc(doc(database.facilities, refForm.data().facility.id))
      .then(facility => {
        setFacilityData({
          patientCategories: defaultPatientTypes,
          ...facility.data(),
        });
        setCurrentReportData({
          id: id,
          ...refForm.data(),
        });
        changeReport(null);
      });
    });
  };

  useEffect(() => {
    if (currentReport) {
      getReportFromId(currentReport);
    } else {
      enqueueSnackbar('Could not load req form to view.'
          + ' Redirecting...',
          {variant: 'warning'});
      navigate("/RequisitionForms");
    }
  }, []);

  return (
      <Page title={"LabPort | Requisition Form"}>
        {currentReportData ?
            <EditReqForm
                facilityData={facilityData}
                currentReportData={currentReportData}/>
            : <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: "center"
            }}>
              <CircularProgress/>
            </Box>
        }
      </Page>
  );
}
