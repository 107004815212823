import moment from "moment";

const untilNow = (from, now = () => moment()) =>
    (withNow = true) => [from(), withNow ? now() : undefined];

export const DATE_RANGES = {
  Today: [moment().startOf("day"), moment().endOf("day")],
  Yesterday: [
    moment().subtract(1, "day").startOf("day"),
    moment().subtract(1, "day").endOf("day"),
  ],
  'This Week': [moment().startOf("week"), moment().endOf("week")],
  'This Month': [moment().startOf("month"), moment().endOf("month")],
  'This Year': [moment().startOf("year"), moment().endOf("year")],
  'Last Week': [
    moment().subtract(1, "week").startOf("week"),
    moment().subtract(1, "week").endOf("week"),
  ],
  'Last Month': [
    moment().subtract(1, "month").startOf("month"),
    moment().subtract(1, "month").endOf("month"),
  ],
  'Last Year': [
    moment().subtract(1, "year").startOf("year"),
    moment().subtract(1, "year").endOf("year"),
  ],
  'Last Hour': untilNow(() => moment().subtract(1, "hour")),
  'Last 8 Hours': untilNow(() => moment().subtract(8, "hour")),
  'Last 12 Hours': untilNow(() => moment().subtract(12, "hour")),
  'Last 24 Hours': untilNow(() => moment().subtract(24, "hour")),
  'Last 7 Days': untilNow(
      () => moment().subtract(7, "days").startOf("day"),
      () => moment().endOf("day")
  ),
  'Last 14 Days': untilNow(
      () => moment().subtract(14, "days").startOf("day"),
      () => moment().endOf("day")
  ),
  'Last 30 Days': untilNow(
      () => moment().subtract(30, "days").startOf("day"),
      () => moment().endOf("day")
  ),
  'Last 60 Days': untilNow(
      () => moment().subtract(60, "days").startOf("day"),
      () => moment().endOf("day")
  ),
  'Last 90 Days': untilNow(
      () => moment().subtract(90, "days").startOf("day"),
      () => moment().endOf("day")
  ),
  'Last 12 Months': untilNow(
      () => moment().subtract(12, "months").startOf("day"),
      () => moment().endOf("day")
  ),
};
