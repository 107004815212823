export const testTypes = [
  "iHealth COVID-19 Rapid Antigen Test",
  "On/Go At-Home COVID-19 Rapid Antigen Test",
  "BD Veritor Rapid COVID-19 Digital Test Kit",
  "DxTerity COVID-19 Saliva At-Home PCR Test Kit",
  "Lucira Check It COVID-19 PCR Test Kit",
  "EmpowerDX At-Home COVID-19 PCR Test",
  "Everlywell COVID-19 PCR Test Home Collection Kit",
  "Indicaid Rapid Home Antigen Test",
  "Detect COVID-19 Starter Kit",
];
