import {useState} from 'react';
import {Link} from "react-router-dom";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {Button, Chip} from "@mui/material";
import {database} from "../components/firebaseConfig";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {useAuth} from "../contexts/CustomAuthProvider";
import {usePatient} from "../contexts/PatientProvider";
import {DATE_RANGES} from "../const/dateRanges";
import {
  addDoc,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import {rolesViews} from "../const/roles";
import Page from "../components/Page";
import {getFirestoreTimestamp} from "../utils/Utils";
import {PrettyPhone} from "../components/PrettyPhone";
import {useSnackbar} from "notistack";

export default function PatientRecords() {
  const {enqueueSnackbar} = useSnackbar();
  const {userData} = useAuth();
  const {changePatient} = usePatient();
  const defaultFacility = userData?.preferences?.defaultFacility;
  const routeAccess = rolesViews[userData?.roles?.role]?.routeAccess;
  const editPatientAccess = (routeAccess ?? []).includes("EditPatient");

  // logic for data limited queries to firebase
  const [dates, setDates] = useState(DATE_RANGES["Last 7 Days"]);
  const rangePickerOnChange = (dates) => {
    if (dates) {
      setDates(dates);
    } else {
      setDates(DATE_RANGES.Today);
    }
  }
  const [data, loading] = useCollectionData(query(database.patients,
      where("facilityId", "==", defaultFacility ?? ""),
      where("author.timestamp", ">=", dates[0].toDate()),
      where("author.timestamp", "<=", dates[1].toDate()),
      orderBy("author.timestamp"),
  ).withConverter(firestoreDataConverter));

  const checkIn = async (id) => {
    //get the patient
    getDoc(doc(database.patients, id)).then(patientDoc => {
      const patientData = patientDoc.data();
      //add to checkin table
      addDoc(database.checkedIn, {
        "patient": {
          ...patientData,
          id: id
        },
        "facilityId": defaultFacility,
        "timestamp": serverTimestamp(),
        "sample": {
          barcode: ""
        }
      }).then(() => {
        enqueueSnackbar("Patient Checked In",
            {variant: 'success'});
      }).catch(() => {
        enqueueSnackbar("Failed to check in patient",
            {variant: 'error'});
      })
    })
  };

  const textUploadLink = (currentPatientData) => {
    addDoc(database.sendSMS, {
      "to": currentPatientData.phone,
      "body": "LabPort🧪: " + currentPatientData.fname +
          ", here is the requested vaccine upload link: https://register.labport.app/vaccine?pid="
          + currentPatientData.id + "&site=" + currentPatientData.facilityId
    }).then(() => {
      enqueueSnackbar("Vaccine link was queued to be sent.",
          {variant: 'success'});
    }).catch((e) => {
      enqueueSnackbar(JSON.stringify(e),
          {variant: 'error'});
    })
  };

  const renderButtons = (cell) => {
    return (
        <>
          <Button
              color={"warning"}
              variant={"outlined"}
              onClick={() => checkIn(cell.id)}>
            Chk-in
          </Button>
          {editPatientAccess &&
              <Button
                  style={{
                    marginLeft: "10px"
                  }}
                  to={"/EditPatient"}
                  onClick={() => {
                    changePatient(cell.id);
                  }}
                  component={Link}
                  variant={"outlined"}>
                Edit
              </Button>
          }
        </>
    );
  }

  const renderVaccineStatus = (cell) => {
    const vaccine = cell.row.vaccine;
    if (vaccine && vaccine.type?.length > 0
        && vaccine.firstDoseDate
        && ((vaccine.type.includes("Moderna")
            || vaccine.type.includes("Pfizer"))
            ? vaccine.secondDoseDate : true)
        && vaccine.boosterDate) {
      return <Chip style={{color: "#00b60c"}} label={"Complete"}/>;
    } else if (vaccine && (vaccine.type?.length > 0
        || vaccine.firstDoseDate)) {
      return <Chip style={{color: "#fdf001"}} label={"Partial"}/>;
    }
    return (
        <Button
            color={"error"}
            variant={"contained"}
            disabled
            onClick={() => textUploadLink(cell.row)}
        >
          Txt upload link
        </Button>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      headerAlign: 'center',
    },
    {
      field: "options",
      headerName: "Options",
      width: editPatientAccess ? 200 : 100,
      sortable: false,
      headerAlign: 'center',
      align: "center",
      renderCell: cell => renderButtons(cell),
    },
    {
      field: 'Date',
      headerName: 'Date Added',
      filterable: false,
      width: 175,
      type: "dateTime",
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value)
      },
      valueGetter: cell =>
          getFirestoreTimestamp(cell.row.author?.timestamp).toDate(),
    },
    {
      field: 'fname',
      headerName: 'First name',
      width: 130,
      headerAlign: 'left',
    },
    {
      field: 'lname',
      headerName: 'Last name',
      width: 130,
      headerAlign: 'left',
    },
    {
      field: 'patientType',
      headerName: 'Category',
      width: 140,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patientType ?? "",
    },
    {
      field: "vaccine",
      headerName: "Vaccine Status",
      width: 155,
      headerAlign: 'left',
      align: "center",
      renderCell: cell => renderVaccineStatus(cell),
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      type: 'date',
      width: 120,
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value)
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 180,
      headerAlign: 'left',
      valueGetter: cell => {
        return cell.row.address?.line1 + " "
            + cell.row.address?.city + ", "
            + cell.row.address?.state + " "
            + cell.row.address?.zip
      },
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 170,
      headerAlign: 'left',
      valueGetter: cell => cell.row.phone,
      renderCell: cell => <PrettyPhone phone={cell.row.phone}/>,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 240,
      headerAlign: 'left',
    },
  ];

  return (
      <Page title={"LabPort | Patient Records"}>
        <CustomDataGrid
            dates={dates}
            rangePickerOnChange={rangePickerOnChange}
            localVar={"Patient"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            initialColumnVisibility={{
              id: false,
              patientType: Boolean(defaultFacility?.settings?.enforcePatientType),
              vaccine: Boolean(defaultFacility?.settings?.enforceVaccineCard),
            }}
        />
      </Page>
  );
}
