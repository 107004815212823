import {Fragment, useEffect, useState} from "react";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import {useAuthState} from "react-firebase-hooks/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useAuth} from "../contexts/CustomAuthProvider";
import {rolesViews} from "../const/roles";
import {auth, database} from "../components/firebaseConfig";
import {LoadingButton} from "@mui/lab";
import Page from "../components/Page";

export default function EditAccount() {
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [facilityLoading, setFacilityLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  let loading = open && options.length === 0;

  const [currentFacility, setCurrentFacility] = useState({});
  const [currentFacilityId, setCurrentFacilityId] = useState(null);

  const {
    userData,
    changeDefaultFacility,
    setCurrentGlobalFacility,
    issuePasswordResetEmail,
  } = useAuth();
  const [currentUser] = useAuthState(auth);

  const sweetAlert = withReactContent(Swal);

  const changePassword = () => {
    setLoadingRequest(true);
    issuePasswordResetEmail(currentUser.email).then(() => {
      setLoadingRequest(false);
      sweetAlert.fire({
        title: <p>Check your email for instructions on how to reset your
          password!</p>,
        icon: "success",
        didOpen: () => {
        }
      }).then(() => {
      });
    }).catch(e => {
      setLoadingRequest(false);
      sweetAlert.fire({
        title: <p>Error!</p>,
        icon: "error",
        text: e.toString(),
        didOpen: () => {
        }
      }).then(() => {
      });
    })
  };

  //grab facility name
  useEffect(() => {
    if (Object.keys(currentFacility).length === 0) {
      setCurrentFacility(userData?.preferences?.defaultFacility);
    }
    getDoc(doc(database.facilities, userData?.preferences?.defaultFacility))
    .then((res) => {
      setCurrentGlobalFacility(res.data().name)
      setCurrentFacility(res.data());

    }).catch(e => {
      console.error(e);
      sweetAlert.fire({
        title: <p>Could not load default facility info</p>,
        text: "Refresh this page. If the problem persists, please get in touch with your account manager.",
        icon: "error",
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    })
  }, []);

  useEffect(() => {
    if (!currentFacilityId) {
      return;
    }
    getDoc(doc(database.facilities, currentFacilityId))
    .then((newFacility) => {
      setCurrentFacility(newFacility.data())
    }).catch((e) => {
      console.log(e)
    })

  }, [currentFacilityId])

  return (
      <Page title={"LabPort | Edit Account"}>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <Typography gutterBottom variant={'h2'} style={{fontSize: "2rem"}}>
              Edit Account
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Grid item sm={12}>
              <Typography gutterBottom variant={'h2'}
                          style={{fontSize: "2rem"}}>
                Current Facility Information
              </Typography>
              <Typography gutterBottom variant={'h3'}
                          style={{fontSize: "1rem"}}>
                Your current default facility is {facilityLoading ? <Skeleton/>
                  : currentFacility.name}
              </Typography>
              <Typography gutterBottom variant={'h3'}
                          style={{fontSize: "1rem"}}>
                Facility phone: {currentFacility.phone}
              </Typography>
              <Typography gutterBottom variant={'h3'}
                          style={{fontSize: "1rem"}}>
                Facility address: {currentFacility.address}
              </Typography>
              {currentFacility.physicians ?
                  <>
                    <Typography gutterBottom variant={'h3'}
                                style={{fontSize: "1rem"}}>
                      {/*Facility Physicians: {JSON.stringify(currentFacility.physicians)}*/}
                      Facility Physicians:
                    </Typography>
                    <Table style={{marginBottom: "20px"}}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Physician Name</TableCell>
                          <TableCell>NPI</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentFacility.physicians.map((row) => (
                            <TableRow key={row.name}>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{row.NPI}</TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                  :
                  ""
              }
              <Typography gutterBottom variant={'h3'}
                          style={{fontSize: "1rem"}}>
                Facility NPI: {currentFacility.NPI}
              </Typography>
              <Typography gutterBottom variant={'h3'}
                          style={{fontSize: "1rem"}}>
                Facility ID: {userData?.preferences?.defaultFacility}
              </Typography>
              <Typography>
                Check-in Link: <a style={{color: "#4949fd"}}
                                  target={"_blank"}
                                  href={`https://checkin.labport.app/?site=${userData?.preferences?.defaultFacility}`}
                                  rel="noreferrer">https://checkin.labport.app/?site={userData?.preferences?.defaultFacility}</a>
              </Typography>
              <Typography>
                Registration Link: <a style={{color: "#4949fd"}}
                                      target={"_blank"}
                                      href={`https://register.labport.app/?site=${userData?.preferences?.defaultFacility}`}
                                      rel="noreferrer">https://register.labport.app/?site={userData?.preferences?.defaultFacility}</a>
              </Typography>
              {currentFacility.settings?.enableSelfReporting && <Typography>
                Self Reporting Link: <a style={{color: "#4949fd"}}
                                      target={"_blank"}
                                      href={`https://selfreport.labport.app/?site=${userData?.preferences?.defaultFacility}`}
                                      rel="noreferrer">https://selfreport.labport.app/?site={userData?.preferences?.defaultFacility}</a>
              </Typography>}
            </Grid>
          </Grid>
          {userData?.roles?.facilityAccess?.length > 1 && <>
            <Grid item sm={12}>
              <Typography gutterBottom variant={'h2'}
                          style={{fontSize: "2rem"}}>
                Change Default Facility
              </Typography>
            </Grid>
            <Grid item lg={5} xs={12}>
              <Autocomplete
                  open={open}
                  onOpen={async () => {
                    setOpen(true);
                    // console.log(userData?.roles?.facilityAccess)
                    //load the name of the facilities instead
                    let facilitiesWithNames = []
                    Promise.all(userData.roles.facilityAccess.map(
                        async (facility) => {
                          // console.log(facility);
                          await getDoc(
                              doc(database.facilities, facility)).then(
                              facility => {
                                facilitiesWithNames.push({
                                  id: facility.id,
                                  name: facility.data().name
                                })
                                // console.log(facility.id)
                              }).catch((e) => {
                            console.error(e)
                            //TODO:Report error to devs
                            // console.log(userData?.roles?.facilityAccess)
                          })
                          // userData?.roles?.facilityAccess[index] = `${userData?.roles?.facilityAccess[index]}${[index]}`
                        }))
                    .then(() => {
                      // console.log(facilitiesWithNames);
                      setOptions(facilitiesWithNames);
                    }).catch((e) => {
                      console.error(e)
                    })
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.id
                      === value.id}
                  getOptionLabel={(option) => option.name}
                  // options={options}
                  // options={userData?.roles?.facilityAccess}
                  options={options}
                  loading={loading}
                  onChange={(event, newValue) => {
                    // console.log(newValue)
                    //change default facility
                    if (newValue) {
                      try {
                        // console.log(`About to change facilities to ${newValue.id}`)
                        setDoc(doc(database.users, currentUser.uid),
                            {"preferences": {"defaultFacility": newValue.id}},
                            {merge: true})
                        .then(() => {
                              setCurrentFacilityId(newValue.id)
                              setCurrentGlobalFacility(newValue.name)
                              sweetAlert.fire({
                                title: <p style={{color: "red"}}>Changed
                                  Facility</p>,
                                icon: "success",
                                toast: true,
                                position: 'bottom-end',
                                text: "User Preferences Updated",
                                timer: 6000,
                                showConfirmButton: false,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                  toast.addEventListener('mouseenter',
                                      Swal.stopTimer)
                                  toast.addEventListener('mouseleave',
                                      Swal.resumeTimer)
                                }
                              });
                              //set facility without refreshing
                              // window.location.reload();
                              changeDefaultFacility(newValue.id)
                            }
                        );
                      } catch (e) {
                        console.error(e)
                        sweetAlert.fire({
                          title: <p>Failed updating your preference</p>,
                          icon: "error",
                          toast: true,
                          position: 'bottom-end',
                          showCancelButton: false,
                          showConfirmButton: false,
                          timerProgressBar: true,
                          didOpen: (toast) => {
                            toast.addEventListener('mouseenter',
                                Swal.stopTimer)
                            toast.addEventListener('mouseleave',
                                Swal.resumeTimer)
                          }
                        });
                      }
                    }
                  }}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          style={{width: "100%"}}
                          label="Facilities you have access to"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                  {loading ? <CircularProgress color="inherit"
                                                               size={20}/>
                                      : null}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                          }}
                      />
                  )}
              />
            </Grid></>}
          {(rolesViews[userData?.roles?.role]?.routeAccess ?? [])
          .includes("TestPatient") ?
              <>
                <Grid item lg={12} sm={12}>
                  <Divider/>
                </Grid>
              </>
              :
              ""
          }
          <Grid item sm={12}>
            <Typography gutterBottom variant={'h2'} style={{fontSize: "2rem"}}>
              Password Reset
            </Typography>
          </Grid>
          <Grid item lg={12} sm={12}>
            <LoadingButton
                style={{width: "300px"}}
                loading={loadingRequest}
                variant={"contained"}
                color={"primary"}
                onClick={changePassword}>
              Send password reset email
            </LoadingButton>
          </Grid>
          <Grid item sm={12}>
            <Typography gutterBottom variant={'h2'} style={{fontSize: "2rem"}}>
              User Permissions
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography gutterBottom variant={'h3'} style={{fontSize: "1rem"}}>
              Your account
              has {rolesViews.display[userData?.roles?.role]} permissions.
            </Typography>
          </Grid>
        </Grid>
      </Page>
  );
}
