import {IconButton, Stack, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {Controller} from "react-hook-form";
import isEmail from 'validator/es/lib/isEmail';
import {isValidPhoneNumber} from 'libphonenumber-js';
import {
  ControlledMuiPhoneNumber,
  ControlledToggleButtonGroup
} from "../utils/ControlledComponents";
import {dailyTime, deliveryTypes} from "../const/deliveryTypes";

export const NotifyList = (props) => {
  const {
    fields,
    remove,
    name,
    control,
    trigger,
    errors,
    update,
    clearErrors,
    setError,
    watching,
  } = props;

  return (
      <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
      >
        {fields.map((field, index) => {
          return (
              <Stack
                  key={field.id}
                  direction="row"
                  justifyContent="center"
                  alignItems="baseline"
                  spacing={2}
              >
                <Controller
                    name={`${name}.${index}.email`}
                    render={({field, fieldState}) => (
                        <TextField
                            {...field}
                            label={"Email"}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            onBlur={(event) => {
                              const email = event.target?.value;
                              if (email == null) {
                                return;
                              }
                              if (!isEmail(email)) {
                                setError(`${name}.${index}.email`,
                                    {
                                      type: "manual",
                                      message: "Invalid Email",
                                    },
                                    {shouldFocus: false}
                                );
                                return;
                              }
                              clearErrors([
                                `${name}.${index}.email`,
                                `${name}.${index}.phone`,
                              ]);
                              trigger(`${name}.${index}.deliveryType`,
                                  {shouldFocus: true})
                              .then((result) => {
                                if (result) {
                                  update({
                                    name: name,
                                    label: "Email",
                                  });
                                }
                              });
                            }}
                            autoComplete={"new-password"}
                            type={"email"}
                            fullWidth
                        />
                    )}
                    control={control}
                />
                <ControlledMuiPhoneNumber
                    label={"Phone Number"}
                    name={`${name}.${index}.phone`}
                    control={control}
                    onBlur={(event) => {
                      const phone = event.target?.value;
                      if (phone == null) {
                        return;
                      }
                      if (!isValidPhoneNumber(phone)) {
                        setError(`${name}.${index}.phone`,
                            {
                              type: "manual",
                              message: "Invalid Phone Number",
                            },
                            {shouldFocus: false}
                        );
                        return;
                      }
                      clearErrors([
                        `${name}.${index}.email`,
                        `${name}.${index}.phone`,
                      ]);
                      trigger(`${name}.${index}.deliveryType`,
                          {shouldFocus: true})
                      .then((result) => {
                        if (result) {
                          update({
                            name: name,
                            label: "Phone",
                          });
                        }
                      });
                    }}
                    autoComplete={"new-password"}
                    type={"tel"}
                    variant={"outlined"}
                    defaultCountry={'us'}
                    disableAreaCodes
                    fullWidth
                />
                <ControlledToggleButtonGroup
                    label={"Delivery Type"}
                    name={`${name}.${index}.deliveryType`}
                    items={deliveryTypes}
                    index={index}
                    update={update}
                    watching={watching}
                    setError={setError}
                    requiredList={["email", "phone"]}
                    list={name}
                    clearError={() =>
                        clearErrors(`${name}.${index}.deliveryType`)
                    }
                    required
                    control={control}
                    color={"primary"}
                    exclusive
                />
                {watching[name][index].deliveryType === "daily" &&
                    <ControlledToggleButtonGroup
                        label={"Daily Time"}
                        name={`${name}.${index}.dailyTime`}
                        items={dailyTime}
                        index={index}
                        update={update}
                        watching={watching}
                        list={name}
                        clearError={() =>
                            clearErrors(`${name}.${index}.dailyTime`)
                        }
                        required
                        control={control}
                        color={"primary"}
                        exclusive
                    />
                }
                <IconButton
                    size={"large"}
                    onClick={() => {
                      remove(index);
                      if(errors) {
                        return;
                      }
                      update({
                        name: name,
                        label: "Notification List",
                      });
                    }}
                    color={"error"}
                >
                  <DeleteIcon/>
                </IconButton>
              </Stack>
          );
        })}
      </Stack>
  );
};
