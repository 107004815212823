import {CSVReader} from 'react-papaparse'
import {useEffect, useRef, useState} from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography
} from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {parsePhoneNumber} from 'libphonenumber-js'
import {serverTimestamp, writeBatch} from "firebase/firestore";
import {auth, database, firestore} from "../components/firebaseConfig";
import {useAuth} from "../contexts/CustomAuthProvider";
import {useAuthState} from "react-firebase-hooks/auth";
import {useClientIP} from "../hooks/useClientIP";
import {CustomSignaturePad} from "../components/SignaturePad";
import Page from "../components/Page";
import {useNavigate} from "react-router-dom";

export default function RegisterPatients() {
  const {userData} = useAuth();
  const signPad = useRef(null);
  const [currentUser] = useAuthState(auth);
  const navigate = useNavigate();
  const [clientIP, loadingClientIP] = useClientIP();
  const [loading, setLoading] = useState(false);
  const sweetAlert = withReactContent(Swal);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!loadingClientIP && !clientIP) {
      sweetAlert.fire({
        title: <p>Could not identify IP address!</p>,
        icon: "error",
        html: <p>Registering patients requires having an IP address.<br/><br/>
          Please disabled browser extensions and addons that are blocking your
          IP address, which usually include ad-blockers. Using LabPort on a
          different browser might give you better results.
        </p>,
      }).then((result) => {
        navigate('/dashboard');
      });
    }
  }, [loadingClientIP, clientIP]);

  const handleError = (err) => {
    console.log(err);
  };

  const handleOnFileLoad = (results) => {
    if (!checked) {
      sweetAlert.fire({
        title: <p>Please agree to the terms!</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      return;
    }
    if (signPad.current.isEmpty()) {
      sweetAlert.fire({
        title: <p>Please sign the form!</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      return;
    }
    setLoading(true)
    if (results.meta.fields.length !== 23) {
      sweetAlert.fire({
        title: <p>CSV File Does Not Match The Template</p>,
        icon: "error",
        html: "please use the template provided.",
      }).then(() => {
      });
      setLoading(false)
      return;
    }
    if (results.errors.length > 0) {
      let errorText = "";
      for (let [i, someError] of results.errors.entries()) {
        errorText += `${i
        + 1}. ${someError.message} at row ${someError.row}.<br/>`
      }
      errorText += `Your upload has been cancelled, please try again.`;
      sweetAlert.fire({
        title: <p>Your file has {results.errors.length} Error(s).</p>,
        icon: "error",
        html: errorText,
      }).then(() => {
      });
      setLoading(false)
      return
    }
    sweetAlert.fire({
      title: <p> {results.data.length} patient record(s) found in this
        file.</p>,
      icon: "warning",
      html: "You cannot delete patients via the portal. Please check data before submission.",
      confirmButtonText: "Add Patients",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        const batch = writeBatch(firestore);
        //need to quantify to 500 at a time. Array of promises?
        let patientRecords = results.data.map(
            (doc) => {
              const signatureImage = signPad.current.toDataURL("image/svg+xml");
              const phoneNumber = parsePhoneNumber(doc['Phone'], 'US');
              return {
                author: {
                  "submittedBy": currentUser.uid,
                  "timestamp": serverTimestamp(),
                  ip: clientIP,
                  signature: signatureImage,
                },
                "fname": doc['First Name'],
                "lname": doc['Last Name'],
                "searchField": doc['First Name'].toLowerCase(),
                "email": doc['Email'],
                "phone": "+" + phoneNumber.countryCallingCode + " "
                    + phoneNumber.formatNational(),
                "dob": new Date(doc['Date of Birth']).toLocaleDateString(
                    'en-US',
                    {year: 'numeric', month: '2-digit', day: '2-digit'}),
                "facilityId": userData?.preferences?.defaultFacility,
                "address": {
                  "line1": doc["Address"],
                  "city": doc["City"],
                  "zip": doc["Zip"],
                  "state": doc["State"],
                },
                "patientType": doc["patientType"] ?? "",
                "ethnicity": doc["Ethnicity"],
                "race": doc["Race"],
                "billTo": doc["Bill To"],
                "sex": doc["Sex"],
                "ssn": doc["ssn"],
                "identification": {
                  "type": doc["ID Type"],
                  "number": doc["ID Number"] ?? "",
                  "exp": doc["ID Expiry (MM/YYYY)"],
                  "state": doc["ID State"]
                },
                insurance: {
                  "carrier": doc["Insurance Provider"] ?? "",
                  "policyNumber": doc["Policy Number"] ?? "",
                  "groupNumber": doc["Group Number"] ?? "",
                  "subscriberName": doc["Subscriber Name"] ?? ""
                }
              }
            }
        );
        for (let patientRecord of patientRecords) {
          console.info(patientRecord);
          batch.set(database.patients, patientRecord);
        }
        batch.commit().then(() => {
          sweetAlert.fire({
            title: <p>Patients Added Successfully</p>,
            icon: "success",
            showConfirmButton: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          setLoading(false)
        }).catch(e => {
          sweetAlert.fire({
            title: <p>Failed to Add Patients</p>,
            icon: "error",
            text: e,
            showConfirmButton: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          setLoading(false)
        })
      }
    });
    // verify upload
    // verify consistancy and empty cells
    // create batch
    // commit batch
    // display success message
  };
  return (
      <Page title={"LabPort | Upload Patients"}>
        {loading && <LinearProgress/>}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Upload Patient Records
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color='textSecondary' style={{fontSize: "1rem"}}>
              Bulk upload your patients using the box below.
              Please note that you must use the provided template to avoid errors.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom color='textSecondary' style={{fontSize:"1rem"}}>
              Patients must be registered prior to submitting a requisition form.
              Please retain a physical copy of the Patient's insurance card and photo identification in your files.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CSVReader
                addRemoveButton
                accept='text/csv, .csv, application/vnd.ms-excel'
                onError={handleError}
                style={{
                  fileNameInfo: {
                    color: "#d44e5c",
                  },
                  fileSizeInfo: {
                    color: "#d44e5c",
                  }
                }}
                config={{
                  error: handleError,
                  header: true,
                  complete: (results, file) => handleOnFileLoad(results, file)
                }}>
              <Typography align="center">
                Drop CSV file here or click to upload.
              </Typography>
            </CSVReader>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
                value={"end"}
                control={<Checkbox
                    required
                    checked={checked}
                    onChange={event=>setChecked(event.target.checked)}
                    color={"primary"}/>}
                label="I certify that the information I'm uploading is correct
                and understand that patient names and date of births cannot be
                altered after submission. The patients has been advised that
                they or their Insurance Provider will be billed by our
                laboratories for any tests performed. Failure to input correct
                insurance information may result in the facility receiving a
                bill for the tests performed."
                labelPlacement={"end"}/>
          </Grid>
          <Grid item xs={12}>
            <CustomSignaturePad ref={signPad}/>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary"
                    style={{marginRight: "20px"}}>
              Click here to Download Excel template
            </Button>
          </Grid>
        </Grid>
      </Page>
  );
}
