import {useState} from 'react';
import {Link} from "react-router-dom";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {Button} from "@mui/material";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {useAuth} from "../contexts/CustomAuthProvider";
import {usePatient} from "../contexts/PatientProvider";
import {DATE_RANGES} from '../const/dateRanges';
import {orderBy, query, where} from "firebase/firestore";
import {database} from "../components/firebaseConfig";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import {getFirestoreTimestamp, restrictDateRange} from "../utils/Utils";
import {rolesViews} from "../const/roles";
import Page from "../components/Page";
import {TestResultChip} from "../components/TestResultChip";
import {PrettyPhone} from "../components/PrettyPhone";

export default function ReqForms() {
  const {userData} = useAuth();
  const {changeReport} = usePatient();
  const defaultFacility = userData?.preferences?.defaultFacility;
  const routeAccess = rolesViews[userData?.roles?.role]?.routeAccess;
  const allowViewForm = (routeAccess ?? []).includes("ReqForm");
  const allowShowSSN = rolesViews[userData?.roles?.role]?.showSSN;
  const renderButtons = (cell) => {
    return <Button
        to={"/ReqForm"}
        onClick={() => {
          changeReport(cell.row.id);
        }}
        component={Link}
        variant={"outlined"}>
      View
    </Button>
  };
  const getOptions = () => {
    return {
      field: "options",
      headerName: "Options",
      width: 100,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: "center",
      renderCell: cell => renderButtons(cell),
    }
  };
  const getSSN = () => {
    return {
      field: "patient.ssn",
      headerName: "SSN",
      width: 180,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.ssn ?? "",
    }
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      headerAlign: 'center',
    },
    ...[(allowViewForm && getOptions())].filter(v => v),
    {
      field: "Date",
      headerName: "Sample Date",
      filterable: false,
      width: 170,
      type: "dateTime",
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value)
      },
      valueGetter: cell =>
          getFirestoreTimestamp(cell.row.sample?.collectionTime).toDate(),
    },
    {
      field: "patient.fname",
      headerName: "First Name",
      width: 130,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.fname,
    },
    {
      field: "patient.lname",
      headerName: "Last Name",
      width: 120,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.lname,
    },
    {
      field: "patient.patientType",
      headerName: "Type",
      width: 120,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.patientType ?? "",
    },
    {
      field: "patient.dob",
      headerName: "Date of birth",
      type: 'date',
      width: 120,
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return Date.parse(param1.value) - Date.parse(param2.value)
      },
      valueGetter: cell => cell.row.patient?.dob,
    },
    {
      field: "result",
      headerName: "Result",
      width: 120,
      headerAlign: 'left',
      valueGetter: cell => cell.row.result ?? "Pending",
      renderCell: cell => <TestResultChip value={cell.value}/>,
    },
    {
      field: "sample.barcode",
      headerName: "Barcode",
      width: 130,
      headerAlign: 'left',
      valueGetter: cell => cell.row.sample?.barcode,
    },
    {
      field: "sample.testPanel",
      headerName: "Test Type",
      width: 130,
      headerAlign: 'left',
      valueGetter: cell => cell.row.sample?.testPanel,
    },
    {
      field: "patient.sex",
      headerName: "Gender",
      width: 100,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.sex,
    },
    {
      field: "patient.address",
      headerName: "Address",
      width: 180,
      headerAlign: 'left',
      valueGetter: cell => {
        return cell.row.patient?.address?.line1 + " "
            + cell.row.patient?.address?.city + ", "
            + cell.row.patient?.address?.state + " "
            + cell.row.patient?.address?.zip
      }
    },
    {
      field: "patient.phone",
      headerName: "Phone",
      width: 170,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.phone,
      renderCell: cell => <PrettyPhone phone={cell.row.patient?.phone}/>,
    },
    {
      field: "email",
      headerName: "Email",
      width: 240,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.email,
    },
    {
      field: "insurance",
      headerName: "Insurance",
      width: 150,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.insurance?.carrier ?? "",
    },
    {
      field: "patient.insurance.policyNumber",
      headerName: "Policy #",
      width: 150,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.insurance?.policyNumber ?? "",
    },
    {
      field: "patient.identification.type",
      headerName: "ID Type",
      width: 150,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.identification?.type ?? "",
    },
    {
      field: "patient.identification.number",
      headerName: "ID Number",
      width: 150,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.identification?.number ?? "",
    },
    {
      field: "patient.identification.state",
      headerName: "ID Issuance",
      width: 150,
      headerAlign: 'left',
      valueGetter: cell => cell.row.patient?.identification?.state ?? "",
    },
    ...[(allowShowSSN && getSSN())].filter(v => v),
  ];

  // logic for data limited queries to firebase
  const initialDates = restrictDateRange(
      userData?.roles?.role,
      "RequisitionForms",
      DATE_RANGES["Last 7 Days"]);
  const [dates, setDates] = useState(initialDates);
  const rangePickerOnChange = (dates) => {
    if (dates) {
      setDates(restrictDateRange(
          userData?.roles?.role,
          "RequisitionForms",
          dates));
    } else {
      setDates(DATE_RANGES["Last 12 Hours"]());
    }
  }
  const [data, loading] = useCollectionData(query(database.reqForms,
      where("facility.id", "==", defaultFacility ?? ""),
      where("sample.collectionTime", ">=", dates[0].toDate()),
      where("sample.collectionTime", "<=", dates[1].toDate()),
      orderBy("sample.collectionTime"),
  ).withConverter(firestoreDataConverter));

  return (
      <Page title={"LabPort | Requisition Forms"}>
        <CustomDataGrid
            dates={dates}
            rangePickerOnChange={rangePickerOnChange}
            localVar={"RequisitionForms"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            initialColumnVisibility={{
              id: false,
              "patient.patientType": Boolean(
                  defaultFacility?.settings?.enforcePatientType),
            }}
        />
      </Page>
  );
}
