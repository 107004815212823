import {initializeApp} from "firebase/app"
import {collection, getFirestore} from "firebase/firestore"
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD3RxXReG5FCD3u-kha-WulGQG0U598BcI",
  authDomain: "hometestbox-aad36.firebaseapp.com",
  projectId: "hometestbox-aad36",
  storageBucket: "hometestbox-aad36.appspot.com",
  messagingSenderId: "894082507458",
  appId: "1:894082507458:web:ab12b82642f8fe737a515b",
  measurementId: "G-1TNM2CCWFN"
};

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const analytics = getAnalytics();
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

export const database = {
  facilities: collection(firestore, 'Facilities'),
  patients: collection(firestore, 'Patients'),
  users: collection(firestore, 'Users'),
  reqForms: collection(firestore, 'ReqForms'),
  selfReports: collection(firestore, 'SelfReports'),
  sendMail: collection(firestore, 'mail'),
  sendSMS: collection(firestore, 'sms'),
  checkedIn: collection(firestore, 'CheckedIn'),
};
