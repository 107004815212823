import './App.css';
import {SnackbarProvider} from "notistack";
import Router from './routes'
import {CustomAuthProvider} from "./contexts/CustomAuthProvider";
import {SelectionProvider} from "./contexts/PatientProvider";
import ThemeConfig from "./theme";

export default function App() {
  return (
      <SnackbarProvider maxSnack={3}>
        <CustomAuthProvider>
          <ThemeConfig>
            <SelectionProvider>
              <Router/>
            </SelectionProvider>
          </ThemeConfig>
        </CustomAuthProvider>
      </SnackbarProvider>
  );
}
