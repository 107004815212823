export const rolesViews = {
  display: {
    developer: "developer",
    superAdmin: "super admin",
    resultUploader: "result uploader",
    siteAdmin: "site admin",
    labPortAdmin: "labport admin",
    teleHealth: "telehealth",
    sampler: "sampler",
    siteManager: "site manager",
    medicalAdmin: "medical admin",
    readOnly: "read only",
    lvSampler: "LV Tech",
    siteNurse: "site nurse",
    labStaff: "lab Staff"
  },
  developer: {
    routeAccess: [
      "adminPanel",
      "CreateFacility", "CreateUser",
      "UserAccess", "EditFacility",
      "RegisterPatient", "RegisterPatients",
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "SelfReports", "SelfReport",
      "CheckedIn", "AntigenReporting",
      "UploadResults", "PendingPCR",
    ],
    rememberDateRange: true,
    fullyEditPatients: true,
    modifyCredits: true,
    viewImageFiles: true,
    editReqForm: true,
    editSelfReport: true,
    auditSelfReport: true,
    testPatient: true,
    showSSN: true,
  },
  superAdmin: {
    routeAccess: [
      "RegisterPatient", "RegisterPatients",
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "SelfReports", "SelfReport",
      "CheckedIn", "AntigenReporting",
      "UploadResults", "PendingPCR",
    ],
    rememberDateRange: true,
    fullyEditPatients: true,
    modifyCredits: true,
    viewImageFiles: true,
    editReqForm: true,
    editSelfReport: true,
    auditSelfReport: true,
    testPatient: true,
    showSSN: true,
  },
  medicalAdmin: {
    routeAccess: [
      "RegisterPatient", "RegisterPatients",
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "CheckedIn",
      "UploadResults",
    ],
    testPatient: true,
  },
  labStaff: {
    routeAccess: [
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "CheckedIn",
      "UploadResults",
    ],
    fullyEditPatients: true,
    modifyCredits: true,
    viewImageFiles: true,
    editReqForm: true,
    testPatient: true,
    showSSN: true,
  },
  siteAdmin: {
    routeAccess: [
      "RegisterPatient", "RegisterPatients",
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "SelfReports", "SelfReport",
      "CheckedIn", "AntigenReporting",
    ],
    editSelfReport: false,
    auditSelfReport: true,
    testPatient: true, //To submit a result for Req Forms
    editReqForm: false,
    fullyEditPatients: false,
    rememberDateRange: false,
    modifyCredits: false,
    viewImageFiles: false, //Patient ID images, not Self Report images
    showSSN: false,
  },
  siteManager: {
    routeAccess: [
      "RegisterPatient",
      "PatientRecords",
      "RequisitionForms", "ReqForm",
    ],
  },
  siteNurse: {
    routeAccess: [
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "CheckedIn", "AntigenReporting",
    ],
    testPatient: true,
  },
  sampler: {
    routeAccess: [
      "PatientRecords", "EditPatient",
      "RequisitionForms", "ReqForm",
      "CheckedIn", "AntigenReporting",
    ],
    testPatient: true,
  },
  lvSampler: {
    routeAccess: [
      "PatientRecords",
      "RequisitionForms",
      "CheckedIn", "AntigenReporting",
    ],
    timeRestrictions: {
      "RequisitionForms": ["Last 12 Hours"],
    },
    testPatient: true,
  },
  resultUploader: {
    routeAccess: [
      "UploadResults", "PendingPCR",
    ],
  },
  teleHealth: {
    routeAccess: [
      "RequisitionForms", "ReqForm",
      "CheckedIn",
    ],
    testPatient: true,
  },
  readOnly: {
    routeAccess: [
      "RequisitionForms", "ReqForm",
    ],
  },
  labPortAdmin: {
    routeAccess: [],
  },
};
