import {createContext, useContext, useEffect, useState} from "react";
import {doc, getDoc, setDoc} from 'firebase/firestore';
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import {auth, database} from "../components/firebaseConfig";
import {Box, CircularProgress} from "@mui/material";

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext)
}

export function CustomAuthProvider(props) {
  const {children} = props;

  const [userData, setUserData] = useState({});
  const [currentGlobalFacility, setCurrentGlobalFacility] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [drawerPinned, setDrawerPinned] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  // function loginWithToken(someToken) {
  //   return signInWithCustomToken(auth, someToken)
  // }

  function logout() {
    setUserData({});
    localStorage.clear();
    return signOut(auth);
  }

  function issuePasswordResetEmail(email) {
    return sendPasswordResetEmail(auth, email)
  }

  function changeDefaultFacility(newFacility) {
    const currentUserData = userData;
    currentUserData.preferences.defaultFacility = newFacility;
    setUserData(currentUserData);
  }

  const changeDarkMode = (value, uid) => {
    if (!uid) {
      return;
    }
    setDoc(doc(database.users, uid),
        {"preferences": {"darkMode": value}},
        {merge: true}).then();
    setDarkMode(value);
    const currentUserData = userData;
    currentUserData.preferences.darkMode = value;
    setUserData(currentUserData);
  };

  const changeDrawerPinned = (value, uid) => {
    if (!uid) {
      return;
    }
    setDoc(doc(database.users, uid),
        {"preferences": {"drawerPinned": value}},
        {merge: true}).then();
    setDrawerPinned(value);
    const currentUserData = userData;
    currentUserData.preferences.drawerPinned = value;
    setUserData(currentUserData);
  };

  useEffect(() => {
    (async () => {
      await onAuthStateChanged(auth, user => {
        if (user) {
          try {
            getDoc(doc(database.users, user.uid)).then(
                (docRef) => {
                  setUserData(docRef.data());
                  setAuthLoading(false);
                  // set theme
                  setDarkMode(docRef.data().preferences.darkMode);
                  // set drawerPin
                  setDrawerPinned(docRef.data().preferences.drawerPinned);
                  // set facility name too
                  getDoc(doc(
                      database.facilities,
                      docRef.data().preferences.defaultFacility))
                  .then((facility) => {
                    setCurrentGlobalFacility(facility.data().name);
                  })
                }
            );
          } catch (e) {
            console.error("error retrieving user data", e);
            setAuthLoading(false);
          }
        } else {
          setUserData({});
          setAuthLoading(false);
        }
      });
    })();
  }, []);

  const value = {
    userData,
    darkMode,
    changeDarkMode,
    drawerPinned,
    changeDrawerPinned,
    currentGlobalFacility,
    setCurrentGlobalFacility,
    // loginWithToken,
    login,
    logout,
    issuePasswordResetEmail,
    changeDefaultFacility,
  };

  return (
      <AuthContext.Provider value={value}>
        {authLoading ? <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          alignItems: "center"
        }}>
          <CircularProgress/>
        </Box> : children}
      </AuthContext.Provider>
  );
}
