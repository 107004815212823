export const symptoms = [
  "None",
  "Mild",
  "Severe",
];

export const exposures = [
  "None",
  "Today",
  "Yesterday",
];
