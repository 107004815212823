import {Box, CircularProgress} from "@mui/material";
import {getBlob, getMetadata, listAll, ref} from "firebase/storage";
import {storage} from "./firebaseConfig";
import {useEffect, useState} from "react";
import {envConfig} from "../envConfig";
import {OutlinedImage} from "./OutlinedImage";

export default function SelfReportImage(props) {
  const {selfReportId} = props;
  const [imgData, setImgData] = useState(undefined);
  const [imageRef, setImageRef] = useState(undefined);

  useEffect(() => {
    if (!selfReportId) {
      return;
    }
    (async () => {
      const imgStorageReference = ref(storage,
          envConfig.fileStorageURL + selfReportId);
      const imgListResult = await listAll(imgStorageReference);
      setImageRef(imgListResult.items.pop());
    })();
  }, [selfReportId]);

  useEffect(() => {
    if(!imageRef) {
      return;
    }
    let imgItem;
    (async () => {
      const imgMetadata = await getMetadata(imageRef);
      const imgBlob = await getBlob(imageRef);
      const imgURL = URL.createObjectURL(imgBlob);
      imgItem = {
        name: imgMetadata.name,
        url: imgURL,
        blob: imgBlob,
      };
      setImgData(imgItem);
    })();
    return function cleanup() {
      URL.revokeObjectURL(imgItem.url)
    }
  }, [imageRef]);

  return (
      <>
        {imgData ?
            <OutlinedImage
                label={"Result Image"}
                src={imgData.url}
                alt={imgData.name}
                loading={"lazy"}
                onClick={() => window.open(imgData.url, '_blank')}
            />
            :
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: "center"
            }}>
              <CircularProgress/>
            </Box>
        }
      </>
  );
}
