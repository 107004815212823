import {useEffect, useState} from "react";
import {parse} from "date-fns";
import {Typography} from "@mui/material";
import {format, utcToZonedTime} from "date-fns-tz";

export const LocalTimeRepresentation = () => {
  const [message, setMessage] = useState(
      "(AM: 8am) (PM: 4pm) (timezone: US/Pacific)");

  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localAM = parse("8", "H", new Date());
    const localPM = parse("16", "H", new Date());
    const serverAM = utcToZonedTime(localAM, 'US/Pacific');
    const serverPM = utcToZonedTime(localPM, 'US/Pacific');
    const displayAM = format(serverAM, 'hbbb');
    const displayPM = format(serverPM, 'hbbb');
    if (timezone) {
      setMessage("(AM: " + displayAM + ") (PM: "
          + displayPM + ") (timezone: " + timezone + ")");
    }
  }, []);

  return (
      <Typography
          variant={"caption"}
          sx={{
            fontStyle: 'italic',
            color: "info.main",
          }}>
        {message}
      </Typography>
  );
};
