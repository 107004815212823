import {TextField} from "@mui/material";

export const OutlinedImage = (props) => {
  const {src, alt, ...otherProps} = props;
  return (
      <TextField
          InputLabelProps={{shrink: true}}
          disabled
          fullWidth
          InputProps={{
            inputComponent: "img",
            inputProps: {
              src,
              style: {height: "auto"},
              alt,
            },
          }}
          {...otherProps}
      />
  );
}
