import {grey} from "@mui/material/colors";
import {useEffect, useMemo} from "react";
import {createTheme} from "@mui/material";
import {useAuth} from "../contexts/CustomAuthProvider";

const getDesignTokens = (mode) => ({
  palette: {
    mode, ...(mode === 'light' ? {
      // palette values for light mode
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      }, secondary: {
        light: '#ff7961',
        main: '#af0202',
        dark: '#ba000d',
      },
      divider: "rgba(0, 0, 0, 0.12)",
      text: {
        primary: grey[900],
        secondary: grey[800],
      },
    } : {
      // palette values for dark mode
      primary: {
        light: '#757ce8',
        main: '#5d80ff',
        dark: '#002884',
        contrastText: '#fff',
      }, divider: "rgb(65,65,65)",
      background: {
        default: '#212121',
        paper: '#212121',
      }, text: {
        primary: '#fff',
        secondary: grey[500],
      },
    }),
  },
});

export default function useCustomTheme() {
  const {darkMode} = useAuth();

  const theme = useMemo(
      () => createTheme(getDesignTokens(darkMode ? 'dark' : 'light')),
      [darkMode]);

  useEffect(() => {
    document.documentElement.style.setProperty('--mui-primary-color',
        theme?.palette?.primary?.main);
    document.documentElement.style.setProperty('--mui-background-color',
        theme?.palette?.background?.default);
  }, [theme]);

  return theme;
};
