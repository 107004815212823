import {
  Card,
  Container,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Page from '../components/Page';
import LoginForm from '../components/LoginForm';
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function Login() {
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
      <RootStyle title="LabPort | Login">
        <LogoOnlyLayout/>
        {!mdDown &&
            <SectionStyle>
              <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
                Hi, Welcome Back
              </Typography>
              <img src="/static/illustrations/illustration_login.png"
                   alt="login"/>
            </SectionStyle>}
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{mb: 5}}>
              <Typography variant="h4" gutterBottom>
                Sign in to LabPort
              </Typography>
              <Typography sx={{color: 'text.secondary'}}>Enter your details
                below.</Typography>
            </Stack>
            <LoginForm/>
            <Typography style={{fontSize: "12px"}}>
              Access to this page is limited to authorized personnel only.
              Information contained in this portal may contain privileged
              and
              confidential information, including patient information
              protected by federal and state privacy laws. It is intended
              only
              for the use of the facility authorized to access this
              information.
            </Typography>
          </ContentStyle>
        </Container>
      </RootStyle>
  );
}
