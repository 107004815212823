import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {styled, Toolbar} from "@mui/material";
import DashboardAppBar from "./DashboardAppBar";
import DashboardDrawer from "./DashboardDrawer";
import {useAuth} from "../../contexts/CustomAuthProvider";

const DRAWER_WIDTH = 240;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({theme}) => ({
  flexGrow: 1,
  color: 'text.primary',
  p: 3,
  overflow: 'auto',
  minHeight: '100%',
  padding: theme.spacing(1),
}));

export default function DashboardLayout() {
  const {drawerPinned, changeDrawerPinned} = useAuth();
  const [toolbarTitle, setToolbarTitle] = useState("Dashboard");
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
  const [expandDesktopDrawer, setExpandDesktopDrawer] = useState(drawerPinned);

  useEffect(()=>{
    setExpandDesktopDrawer(drawerPinned)
  },[drawerPinned]);

  return (
      <RootStyle>
        <DashboardAppBar
            expandDesktopDrawer={expandDesktopDrawer}
            setToolbarTitle={setToolbarTitle}
            setOpenMobileDrawer={setOpenMobileDrawer}
            toolbarTitle={toolbarTitle}
            drawerWidth={DRAWER_WIDTH}/>
        <DashboardDrawer
            drawerWidth={DRAWER_WIDTH}
            pinDesktopDrawer={drawerPinned}
            setPinDesktopDrawer={changeDrawerPinned}
            setToolbarTitle={setToolbarTitle}
            expandDesktopDrawer={expandDesktopDrawer}
            setExpandDesktopDrawer={setExpandDesktopDrawer}
            openMobileDrawer={openMobileDrawer}
            setOpenMobileDrawer={setOpenMobileDrawer}/>
        <MainStyle>
          <Toolbar/>
          <Outlet/>
        </MainStyle>
      </RootStyle>
  );
}
