import RegisterPatient from "../pages/RegisterPatient";
import RegisterPatients from "../pages/RegisterPatients";
import PatientRecords from "../pages/PatientRecords";
import ReqForms from "../pages/ReqForms";
import AntigenReporting from "../pages/AntigenReporting";
import PendingPCR from "../pages/PendingPCR";
import UploadResults from "../pages/UploadResults";
import CheckedIn from "../pages/CheckedIn";
import Admin from "../pages/Admin";
import Home from "../pages/Home";
import EditAccount from "../pages/EditAccount";
import SelfReports from "../pages/SelfReports";
import CreateFacility from "../pages/CreateFacility";
import CreateUser from "../pages/CreateUser";
import UserAccess from "../pages/UserAccess";
import EditFacility from "../pages/EditFacility";
import EditPatient from "../pages/EditPatient";
import ReqForm from "../pages/ReqForm";
import SelfReport from "../pages/SelfReport";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import NotificationAddOutlinedIcon
  from '@mui/icons-material/NotificationAddOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import ManageAccountsOutlinedIcon
  from '@mui/icons-material/ManageAccountsOutlined';
import AdminPanelSettingsOutlinedIcon
  from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CameraRollOutlinedIcon from '@mui/icons-material/CameraRollOutlined';

export const navList = [
  {
    name: "dashboard",
    path: "/dashboard",
    component: <Home/>,
    icon: <DashboardOutlinedIcon/>,
    navTitle: "Dashboard",
    toolbarTitle: "Dashboard",
    isNotRestricted: true,
    divider: true,
  },
  {
    name: "RegisterPatient",
    path: "/RegisterPatient",
    component: <RegisterPatient/>,
    icon: <PersonAddAltOutlinedIcon/>,
    navTitle: "Register Patient",
    toolbarTitle: "Register Patient",
  },
  {
    name: "RegisterPatients",
    path: "/RegisterPatients",
    component: <RegisterPatients/>,
    icon: <GroupAddOutlinedIcon/>,
    navTitle: "Upload Patients",
    toolbarTitle: "Upload Records",
  },
  {
    name: "PatientRecords",
    path: "/PatientRecords",
    component: <PatientRecords/>,
    icon: <PersonSearchOutlinedIcon/>,
    navTitle: "Browse Patients",
    toolbarTitle: "Patient Records",
  },
  {
    name: "AntigenReporting",
    path: "/AntigenReporting",
    component: <AntigenReporting/>,
    icon: <NotificationAddOutlinedIcon/>,
    navTitle: "Report Antigen",
    toolbarTitle: "Antigen Reporting",
  },
  {
    name: "PendingPCR",
    path: "/PendingPCR",
    component: <PendingPCR/>,
    icon: <PendingActionsIcon/>,
    navTitle: "Pending PCR",
    toolbarTitle: "Pending PCR",
  },
  {
    name: "UploadResults",
    path: "/UploadResults",
    component: <UploadResults/>,
    icon: <UploadFileIcon/>,
    navTitle: "Upload Results",
    toolbarTitle: "Upload Results",
  },
  {
    name: "RequisitionForms",
    path: "/RequisitionForms",
    component: <ReqForms/>,
    icon: <FindInPageOutlinedIcon/>,
    navTitle: "Requisition Forms",
    toolbarTitle: "Requisition Forms",
  },
  {
    name: "SelfReports",
    path: "/SelfReports",
    component: <SelfReports/>,
    icon: <CameraRollOutlinedIcon/>,
    navTitle: "Self Reports",
    toolbarTitle: "Self Reports",
    divider: true,
  },
  {
    name: "CheckedIn",
    path: "/CheckedIn",
    component: <CheckedIn/>,
    icon: <CloudDoneOutlinedIcon/>,
    navTitle: "Checked In",
    toolbarTitle: "Checked In Patients",
    divider: true,
  },
  {
    name: "adminPanel",
    path: "/Admin",
    component: <Admin/>,
    icon: <AdminPanelSettingsOutlinedIcon/>,
    navTitle: "Admin Settings",
    toolbarTitle: "Admin Settings"
  },
  {
    name: "EditAccount",
    path: "/EditAccount",
    component: <EditAccount/>,
    icon: <ManageAccountsOutlinedIcon/>,
    navTitle: "Edit Account",
    toolbarTitle: "Edit Account",
    isNotRestricted: true,
  },
  // {
  //   name: "signOut",
  //   path: undefined,
  //   component: undefined,
  //   icon: <LogoutOutlinedIcon/>,
  //   navTitle: undefined,
  //   toolbarTitle: "Sign Out",
  //   isNotRestricted: true,
  // },
  // {
  //   name: "darkMode",
  //   path: undefined,
  //   component: undefined,
  //   icon: <NightsStayOutlinedIcon/>,
  //   navTitle: undefined,
  //   toolbarTitle: "Dark Mode",
  //   isNotRestricted: true,
  //   divider: true,
  // },
  // {
  //   name: "EditPatient",
  //   path: "/EditPatient",
  //   component: <EditPatient/>,
  //   icon: undefined,
  //   navTitle: undefined,
  //   toolbarTitle: undefined
  // },
  // {
  //   name: "ReqForm",
  //   path: "/ReqForm",
  //   component: <ReqForm/>,
  //   icon: undefined,
  //   navTitle: undefined,
  //   toolbarTitle: undefined
  // },
];

export const navComponentList = {
  RegisterPatient: <RegisterPatient/>,
  RegisterPatients: <RegisterPatients/>,
  PatientRecords: <PatientRecords/>,
  AntigenReporting: <AntigenReporting/>,
  PendingPCR: <PendingPCR/>,
  UploadResults: <UploadResults/>,
  RequisitionForms: <ReqForms/>,
  SelfReports: <SelfReports/>,
  CheckedIn: <CheckedIn/>,
  CreateFacility: <CreateFacility/>,
  CreateUser: <CreateUser/>,
  UserAccess: <UserAccess/>,
  EditFacility: <EditFacility/>,
  EditPatient: <EditPatient/>,
  ReqForm: <ReqForm/>,
  SelfReport: <SelfReport/>,
};
