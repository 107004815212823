import {useEffect, useState} from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {Box, CircularProgress} from "@mui/material";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../components/firebaseConfig";
import {usePatient} from "../contexts/PatientProvider";
import EditPatientForm from "../components/EditPatientForm";
import {defaultPatientTypes} from "../const/defaultPatientTypes";
import Page from "../components/Page";

export default function EditPatient() {
  const {currentPatient, changePatient} = usePatient();
  const [patientData, setPatientData] = useState(null);
  const [facilityData, setFacilityData] = useState(null);
  const sweetAlert = withReactContent(Swal);
  const navigate = useNavigate();

  const getPatientFromId = (id) => {
    getDoc(doc(database.patients, id))
    .then((patient) => {
      getDoc(doc(database.facilities, patient.data().facilityId))
      .then(facility => {
        setFacilityData({
          patientCategories: defaultPatientTypes,
          ...facility.data(),
        });
        setPatientData({
          id: currentPatient,
          ...patient.data(),
        });
        changePatient(null);
      });
    });
  };

  useEffect(() => {
    if (currentPatient) {
      getPatientFromId(currentPatient);
    } else {
      sweetAlert.fire({
        title: <p>Could not load patient. Redirecting</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
      navigate("/PatientRecords");
    }
  }, []);

  return (
      <Page title={"LabPort | Edit Patient"}>
        {patientData ?
            <EditPatientForm
                facilityData={facilityData}
                patientData={patientData}/>
            : <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: "center"
            }}>
              <CircularProgress/>
            </Box>
        }
      </Page>
  );
}
