import {Navigate, useRoutes} from 'react-router-dom';
import RequireAuth from "./components/RequireAuth";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import DashboardLayout from "./layouts/dashboard";
import Home from "./pages/Home";
import EditAccount from "./pages/EditAccount";
import Admin from "./pages/Admin";
import Login from "./pages/Login";
import Page404 from "./pages/Page404";
import {rolesViews} from "./const/roles";
import {useAuth} from "./contexts/CustomAuthProvider";
import {navComponentList} from "./const/navList";

export default function Router() {
  const {userData} = useAuth();

  const routeAccess = rolesViews[userData?.roles?.role]?.routeAccess;

  const routeItem = (route) => {
    return {
      ...((routeAccess ?? []).includes(route) && {
        path: route,
        element: navComponentList[route],
      })
    };
  };

  return useRoutes([
    {
      element: <RequireAuth/>,
      children: [
        {
          element: <DashboardLayout/>,
          children: [
            {path: 'dashboard', element: <Home/>},
            routeItem("RegisterPatient"),
            routeItem("RegisterPatients"),
            routeItem("PatientRecords"),
            routeItem("AntigenReporting"),
            routeItem("PendingPCR"),
            routeItem("UploadResults"),
            routeItem("RequisitionForms"),
            routeItem("SelfReports"),
            routeItem("CheckedIn"),
            {
              path: 'admin',
              element: <Admin/>,
              children: [
                {element: <Navigate to="/Admin/EditFacility" replace/>},
                routeItem("CreateFacility"),
                routeItem("CreateUser"),
                routeItem("UserAccess"),
                routeItem("EditFacility"),
              ],
            },
            {path: 'EditAccount', element: <EditAccount/>},
            routeItem("EditPatient"),
            routeItem("ReqForm"),
            routeItem("SelfReport"),
            {path: '404', element: <Page404/>},
            {path: '*', element: <Navigate to="/404"/>},
          ],
        },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout/>,
      children: [
        {path: 'login', element: <Login/>},
        {path: '/', element: <Navigate to="/dashboard"/>},
      ],
    },
    {path: '*', element: <Navigate to="/" replace/>}
  ]);
}
