import {
  Box,
  Button,
  CircularProgress,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
  Typography
} from "@mui/material";
import {getBlob, getMetadata, listAll, ref} from "firebase/storage";
import {storage} from "./firebaseConfig";
import {useEffect, useState} from "react";
import {envConfig} from "../envConfig";

export default function MasonryImageList(props) {
  const {folderId} = props;
  const [openImages, setOpenImages] = useState(false);
  const [loadingImages, setLoadingImages] = useState(true);
  const [imgData, setImgData] = useState([]);
  const [imageListItems, setImageListItems] = useState([]);

  const toggleOpenImages = () => {
    setOpenImages(!openImages);
  }

  useEffect(() => {
    if (!folderId) {
      return;
    }
    (async () => {
      const imgStorageReference = ref(storage,
          envConfig.fileStorageURL + folderId);
      const imgListResult = await listAll(imgStorageReference);
      setImageListItems(imgListResult.items);
    })();
  }, [folderId]);

  useEffect(() => {
    if (!openImages) {
      return;
    }
    let imgList;
    (async () => {
      const imgPromises = imageListItems.map(async (imgRef) => {
        const imgMetadata = await getMetadata(imgRef);
        const imgBlob = await getBlob(imgRef);
        const imgURL = URL.createObjectURL(imgBlob)
        return {
          name: imgMetadata.name,
          url: imgURL,
          blob: imgBlob,
        };
      });
      imgList = await Promise.all(imgPromises);
      setImgData(imgList);
      setLoadingImages(false);
    })();
    return function cleanup() {
      imgList.forEach((image) => {
        URL.revokeObjectURL(image.url)
      });
    }
  }, [openImages, imageListItems]);

  return (
      <Box sx={{overflowY: 'scroll'}}>
        {imageListItems?.length > 0 ?
            (openImages ?
                    (loadingImages ?
                            <Box sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: "center"
                            }}>
                              <CircularProgress/>
                            </Box>
                            :
                            <ImageList variant="masonry" cols={3} gap={8}>
                              {imgData.map((item, i) => (
                                  <Tooltip key={i} title={item.name}>
                                    <ImageListItem>
                                      <ImageListItemBar
                                          title={item.name}
                                          // subtitle={item.author}
                                      />
                                      <img
                                          src={item.url}
                                          srcSet={item.url}
                                          alt={item.name}
                                          loading="lazy"
                                          onClick={() =>
                                              window.open(item.url, '_blank')
                                          }
                                      />
                                    </ImageListItem>
                                  </Tooltip>
                              ))}
                            </ImageList>
                    )
                    :
                    <Button variant={"contained"} onClick={toggleOpenImages}>
                      Reveal Image(s)
                    </Button>
            )
            :
            <Typography>No Files Found</Typography>
        }
      </Box>
  );
}
