import {forwardRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  Stack,
  TextField,
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useAuth} from "../contexts/CustomAuthProvider";
import MuiAlert from "@mui/material/Alert";
import isEmail from 'validator/es/lib/isEmail';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant={"filled"} {...props} />;
});

export default function LoginForm() {
  const {login} = useAuth();
  const navigate = useNavigate();
  const {
    register,
    // control,
    formState: {isSubmitting, errors},
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: true,
    },
  });
  const [showPassword, setShowPassword] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };
  const displaySnack = (message, severity) => {
    setSnackMessage(message);
    setSeverity(severity);
    setSnackOpen(true);
  };
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const onSubmit = async (data) => {
    displaySnack("Logging in…", "info");
    try {
      // await auth.setPersistence(data.remember ?
      //     browserLocalPersistence : browserSessionPersistence)
      await login(data.email, data.password);
      navigate('/dashboard', {replace: true});
    } catch (e) {
      displaySnack(
          "Login Failed. If you believe this is an error, please contact your Vivera representative to open a ticket.",
          "error");
    }
  };

  return (<>
    <Snackbar open={snackOpen} autoHideDuration={6000}
              onClose={handleSnackClose}>
      <Alert onClose={handleSnackClose} severity={severity}
             sx={{width: '100%'}}>
        {snackMessage}
      </Alert>
    </Snackbar>
    <form autoComplete={"off"} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <TextField
            inputProps={{
              ...register("email", {
                validate: (value) => isEmail(value),
              })
            }}
            label={"Email"}
            autoComplete={"email"}
            required
            type={"email"}
            variant={"outlined"}
            error={Boolean(errors.email)}
            helperText={Boolean(errors.email) && "Invalid Email"}
            fullWidth
        />
        <TextField
            fullWidth
            required
            autoComplete={"current-password"}
            type={showPassword ? 'text' : 'password'}
            label={"Password"}
            {...register('password')}
            InputProps={{
              endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton onClick={handleShowPassword} edge={"end"}>
                      {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                    </IconButton>
                  </InputAdornment>
              ),
            }}
        />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}
             sx={{my: 2}}>
        {/*<ControlledCheckbox*/}
        {/*    name={"remember"}*/}
        {/*    control={control}*/}
        {/*    label={"Remember me"}*/}
        {/*/>*/}
        <Link
            variant={"subtitle2"}
            href={"mailto:d.harvey@viveraPharma.com?subject=PortalForgotPassword&body=[put%20your%20email%20here]"}
        >
          Forgot password?
        </Link>
      </Stack>
      <LoadingButton
          fullWidth
          size={"large"}
          type={"submit"}
          variant={"contained"}
          loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </form>
  </>);
}
