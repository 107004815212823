import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {Stack} from "@mui/material";
import {memo} from "react";
import {useSnackbar} from "notistack";
import PhysicianListItem from "./PhysicianListItem";

const PhysicianList = memo((props) => {
  const {
    fields,
    remove,
    name,
    control,
    trigger,
    errors,
    update,
    onDragEnd,
  } = props;
  const {enqueueSnackbar} = useSnackbar();

  const removeItem = (index) => {
    if (fields.length > 1) {
      remove(index);
      if (errors) {
        return;
      }
      update({
        name: name,
        label: "Facility Physicians List",
      });
    } else {
      enqueueSnackbar(
          "The facility needs at least 1 physician.",
          {variant: "error"});
    }
  };

  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
            droppableId="droppable-physician"
        >
          {(provided) => (
              <Stack
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={2}
              >
                {fields.map((field, index) => (
                    <PhysicianListItem
                        key={field.id}
                        id={field.id}
                        name={name}
                        control={control}
                        trigger={trigger}
                        index={index}
                        update={update}
                        remove={removeItem}
                    />
                ))}
                {provided.placeholder}
              </Stack>
          )}
        </Droppable>
      </DragDropContext>
  );
});

export default PhysicianList;
