import {useState} from "react";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {DATE_RANGES} from "../const/dateRanges";
import {orderBy, query, where} from "firebase/firestore";
import {database} from "../components/firebaseConfig";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import Page from "../components/Page";

export default function PendingPCR() {
  const [dates, setDates] = useState(DATE_RANGES["Last 7 Days"]);
  const rangePickerOnChange = (dates) => {
    if (dates) {
      setDates(dates);
    } else {
      setDates(DATE_RANGES.Today);
    }
  }
  const [data, loading] = useCollectionData(query(database.reqForms,
      where("sample.testPanel", "==",
          "PCR - COVID-19 SARS-COV-2 MOLECULAR TEST"),
      where("result", "==", null),
      where("sample.collectionTime", ">=", dates[0].toDate()),
      where("sample.collectionTime", "<=", dates[1].toDate()),
      orderBy("sample.collectionTime", "asc"),
  ).withConverter(firestoreDataConverter));

  function getActualTimeStamp(params) {
    return Date.parse(params.value)
  }

  const columns = [
    //headers [ results, first name, last name, DOB, barcode ],
    {
      field: 'id',
      headerName: 'Req Form ID',
      width: 200,
      headerAlign: 'left',
    },
    {
      field: 'facility.name',
      headerName: 'Facility Name',
      width: 140,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.facility) {
          return data.row.facility.name
        }
      },
    },
    {
      field: 'Date',
      headerName: 'Sample Timestamp',
      width: 200,
      filterable: false,
      type: "dateTime",
      headerAlign: 'left',
      sortComparator: (v1, v2, param1, param2) => {
        return getActualTimeStamp(param1) - getActualTimeStamp(param2)
      },
      valueGetter: (data) => {
        return new Date(
            data.row.sample.collectionTime.seconds * 1000).toLocaleString()
      },
    },
    {
      field: 'barcode',
      headerName: 'Barcode',
      width: 130,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.sample.barcode
        }
      },
    },
    {
      field: 'fname',
      headerName: 'First name',
      width: 130,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.fname
        }
      },
    },
    {
      field: 'lname',
      headerName: 'Last name',
      width: 130,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.lname
        }
      },
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      type: 'date',
      width: 140,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.dob
        }
      },
    },
  ];

  return (
      <Page title={"LabPort | Pending PCR"}>
        <CustomDataGrid
            dates={dates}
            rangePickerOnChange={rangePickerOnChange}
            localVar={"PendingPCR"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            disableSelectionOnClick
            initialColumnVisibility={{
              id: false,
            }}
        />
      </Page>
  );
}
