import {Alert, AlertTitle, Button, Grid, Typography} from "@mui/material";
import Page from "../components/Page";
import {rolesViews} from "../const/roles";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../contexts/CustomAuthProvider";
import {getDocs, limit, query, where} from "firebase/firestore";
import {database} from "../components/firebaseConfig";
import {auditResults} from "../const/auditResults";

export default function Home() {
  const {userData} = useAuth();
  const [auditsAvailable, setAuditsAvailable] = useState(false);

  useEffect(() => {
    if (!userData?.preferences?.defaultFacility) {
      return;
    }
    const auditQuery = query(database.selfReports,
        where("facility.id", "==", userData.preferences.defaultFacility),
        where("audit.result", "==", auditResults.pending),
        limit(1));
    (async () => {
      const auditQuerySnapshot = await getDocs(auditQuery);
      setAuditsAvailable(!auditQuerySnapshot.empty);
    })();
  }, [userData]);

  return (
      <Page title={"LabPort | Dashboard"}>
        <Grid container spacing={3}>
          {(rolesViews[userData?.roles?.role]?.routeAccess ?? [])
              .includes("SelfReports") && auditsAvailable &&
              <Grid item xs={12}>
                <Alert
                    severity="info"
                    action={
                      <Button
                          size={"large"}
                          color={"inherit"}
                          to={"/SelfReports"}
                          component={Link}
                          variant={"outlined"}>
                        View
                      </Button>
                    }
                >
                  <AlertTitle>Self Reports in Queue!</AlertTitle>
                  There are self reports waiting to be audited — <strong>
                  <Link to={"/SelfReports"}>check it out!</Link></strong>
                </Alert>
              </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="h4">
              Welcome to your LabPort
            </Typography>
          </Grid>
        </Grid>
      </Page>
  );
}
