export const auditChoices = [
  {
    choice: "Approved",
    name: "approve",
    title: "Approve",
  },
  {
    choice: "Rejected",
    name: "insufficient",
    message: "Uploaded image blurry/not in frame.",
    title: "Reject: Too Blurry or Unreadable",
  },
  {
    choice: "Rejected",
    name: "unsuitable",
    message: "Uploaded image did not meet verification requirements.",
    title: "Reject: Unsuitable/Inappropriate",
  },
  {
    choice: "Rejected",
    name: "incorrect",
    message: "Uploaded image does not match self-report.",
    title: "Reject: Incorrect Result",
  },
  {
    choice: "Rejected",
    name: "expired",
    message: "Test older than the allotted time frame of 12 hours. New test required.",
    title: "Reject: Image Too Old",
  },
];
