import {Controller} from "react-hook-form";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import {isValidPhoneNumber} from 'libphonenumber-js';
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {format, isValid} from "date-fns";

export const ControlledAutocomplete = (props) => {
  const {
    name,
    options,
    control,
    multiple,
    required,
    disabled,
    freeSolo,
    autoComplete,
    autoCompleteProps,
    ...otherProps
  } = props;
  return (
      <Controller
          control={control}
          defaultValue={multiple ? [] : null}
          name={name}
          render={({field: {onChange, value, ...props}}) => (
              <Autocomplete
                  {...props}
                  {...autoCompleteProps}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  onBlur={(newValue) => {
                    if (multiple) {
                      if (freeSolo && newValue?.target?.value) {
                        onChange([...value, newValue?.target?.value]);
                      }
                    } else {
                      if (freeSolo && newValue?.target?.value) {
                        onChange(newValue?.target?.value);
                      }
                    }
                  }}
                  options={options}
                  freeSolo={freeSolo}
                  disabled={disabled}
                  multiple={multiple}
                  value={value ?? (multiple ? [] : null)}
                  renderInput={(params) =>
                      <TextField
                          {...params}
                          {...otherProps}
                          required={required ?
                              (multiple ? !(value?.length > 0) : true)
                              : false}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoComplete,
                          }}
                      />
                  }
              />
          )}
      />
  );
}

export const ControlledMuiPhoneNumber = (props) => {
  const {name, control, required, ...otherProps} = props;
  return (
      <Controller
          control={control}
          name={name}
          rules={{
            validate: (value) => {
              if (required) {
                if (value && isValidPhoneNumber(value)) {
                  return true;
                }
                return "Invalid Phone Number";
              } else {
                if (value && !isValidPhoneNumber(value)) {
                  return "Invalid Phone Number";
                }
                return true;
              }
            },
          }}
          render={({field, fieldState}) => (
              <MuiPhoneNumber
                  {...field}
                  {...otherProps}
                  required={required}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
              />
          )}
      />
  );
}

export const ControlledSelect = (props) => {
  const {name, label, control, required, items, ...otherProps} = props;
  return (
      <Controller
          control={control}
          defaultValue={''}
          name={name}
          render={({field}) => (
              <FormControl
                  required={required}
                  fullWidth
              >
                <InputLabel>{label}</InputLabel>
                <Select
                    label={label}
                    {...field}
                    {...otherProps}
                >
                  {items && items.map((item) => (
                      <MenuItem
                          value={item}
                          key={item}
                      >
                        {item}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
          )}
      />
  );
}

export const ControlledDesktopDatePicker = (props) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    desktopDatePickerProps,
    ...otherProps
  } = props;
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
              field,
              fieldState,
            }) => (
                <DesktopDatePicker
                    {...desktopDatePickerProps}
                    disableOpenPicker
                    inputFormat={inputFormat}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              type: "date",
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
}

export const ControlledDesktopDateTimePicker = (props) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    desktopDateTimePicker,
    ...otherProps
  } = props;
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
              field,
              fieldState,
            }) => (
                <DesktopDateTimePicker
                    {...desktopDateTimePicker}
                    disableOpenPicker
                    inputFormat={inputFormat}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              type: "date",
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
}

export const ControlledMobileDateTimePicker = (props) => {
  const {
    name,
    control,
    inputFormat,
    disableFuture,
    minimumDate,
    disabled,
    mobileDateTimePicker,
    ...otherProps
  } = props;
  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
            control={control}
            defaultValue={null}
            name={name}
            rules={{
              validate: (value) => {
                if (value && !isValid(new Date(value))) {
                  return "Invalid Date";
                }
                if (value && disableFuture &&
                    new Date(value).getTime() > Date.now()) {
                  return "Can't be a future date.";
                }
                if (value && minimumDate &&
                    new Date(value).getTime() < minimumDate) {
                  return "Invalid Date";
                }
                return true;
              },
            }}
            render={({
              field,
              fieldState,
            }) => (
                <MobileDateTimePicker
                    {...mobileDateTimePicker}
                    value={field.value}
                    disableFuture={disableFuture}
                    disabled={disabled}
                    onChange={(value) => {
                      let newValue = value;
                      try {
                        newValue = format(value, inputFormat);
                      } catch (error) {
                        //todo how to avoid this try/catch?
                      }
                      field.onChange(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField
                            fullWidth
                            {...params}
                            {...otherProps}
                            error={Boolean(fieldState.error)}
                            helperText={fieldState.error?.message}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            {...field}
                        />
                    }
                />
            )}
        />
      </LocalizationProvider>
  );
};

export const ControlledCheckbox = (props) => {
  const {name, control, label, ...otherProps} = props;
  return (
      <Controller
          control={control}
          name={name}
          render={({field}) => (
              <FormControlLabel
                  control={
                    <Checkbox
                        {...field}
                        {...otherProps}
                        onChange={(e) => field.onChange(e.target.checked)}
                        checked={field.value}
                    />
                  }
                  label={label}
              />
          )}
      />
  );
}

export const ControlledSwitch = (props) => {
  const {name, control, label, update, ...otherProps} = props;
  return (
      <Controller
          control={control}
          name={name}
          render={({field}) => (
              <FormControlLabel
                  label={label}
                  control={
                    <Switch
                        {...field}
                        {...otherProps}
                        checked={field.value}
                        onChange={(e, newValue) => {
                          field.onChange(newValue);
                          update({
                            name: name,
                            label: label,
                          });
                        }}
                    />
                  }
              />
          )}
      />
  );
};

export const ControlledToggleButtonGroup = (props) => {
  const {
    name,
    list,
    label,
    control,
    items,
    index,
    clearError,
    requiredList,
    setError,
    watching,
    required,
    update,
    ...otherProps
  } = props;
  return (
      <Controller
          control={control}
          defaultValue={''}
          name={name}
          rules={{required: required}}
          render={({field, fieldState, formState}) => (
              <ToggleButtonGroup
                  {...field}
                  {...otherProps}
                  required={required}
                  onChange={(e, newValue) => {
                    if (newValue == null) {
                      return;
                    }
                    if (requiredList && !requiredList.some(
                        (item) => watching[list][index][item])) {
                      requiredList.forEach((item) => {
                        setError(`${list}.${index}.${item}`,
                            {type: "manual"},
                            {shouldFocus: false}
                        );
                      });
                      return;
                    }
                    if (formState.errors[list]
                        && formState.errors[list][index]) {
                      if (requiredList && requiredList.some(
                          (item) => formState.errors[list][index][item])) {
                        return;
                      }
                    }
                    clearError();
                    field.onChange(newValue);
                    update({
                      name: list,
                      label: label,
                    });
                  }}
              >
                {items && items.map((item) => (
                    <ToggleButton
                        sx={{
                          ...(fieldState.error && {bgcolor: 'warning.main'}),
                          whiteSpace: "nowrap",
                        }}
                        value={item.value}
                        key={item.value}
                    >
                      {item.name}
                    </ToggleButton>
                ))}
              </ToggleButtonGroup>
          )}
      />
  );
};
