import {createContext, useContext, useState} from "react";

const SelectionContext = createContext();

export function usePatient() {
  return useContext(SelectionContext)
}

export function SelectionProvider({children}) {
  const [currentPatient, setCurrentPatient] = useState();
  const [currentReport, setCurrentReport] = useState();
  const [currentSelfReport, setCurrentSelfReport] = useState();

  function changePatient(patient) {
    return setCurrentPatient(patient);
  }

  function changeReport(report) {
    return setCurrentReport(report);
  }

  function changeSelfReport(report) {
    return setCurrentSelfReport(report);
  }

  const value = {
    currentPatient,
    changePatient,
    currentReport,
    changeReport,
    currentSelfReport,
    changeSelfReport,
  };

  return (
      <SelectionContext.Provider value={value}>
        {children}
      </SelectionContext.Provider>
  )
}
