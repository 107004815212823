import {useEffect, useRef, useState} from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore"
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from 'notistack';
import {Link} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {auth, database} from "./firebaseConfig";
import {states} from "../const/states";
import {usaStates} from "../const/usaStates";
import {insurances} from "../const/insurances";
import {races} from "../const/races"
import {sexes} from "../const/sexes"
import {ethnicities} from "../const/ethnicities"
import {entities} from "../const/entities"
import {identificationTypes} from "../const/identificationTypes"
import {useAuth} from "../contexts/CustomAuthProvider";
import {rolesViews} from "../const/roles";
import {selfReportNotify} from "./notify";
import parsePhoneNumber from "libphonenumber-js";
import {useForm} from "react-hook-form";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  ControlledAutocomplete,
  ControlledDesktopDatePicker,
  ControlledDesktopDateTimePicker,
  ControlledMuiPhoneNumber,
  ControlledSelect
} from "../utils/ControlledComponents";
import {countries} from "../const/countries";
import {vaccines} from "../const/vaccines";
import {CustomSignaturePad} from "./SignaturePad";
import {useClientIP} from "../hooks/useClientIP";
import {OutlinedImage} from "./OutlinedImage";
import {
  getDirtyValues,
  getFirestoreTimestamp,
  scrubUndefined
} from "../utils/Utils";
import MasonryImageList from "./MasonryImageList";
import {auditChoices} from "../const/auditChoices";
import {results} from "../const/results";
import {testTypes} from "../const/testTypes";
import {rejectReasons} from "../const/rejectReasons";
import {auditResults} from "../const/auditResults";
import {exposures, symptoms} from "../const/questionnaire";

export default function SelfReportForm(props) {
  const {facilityData, currentReportData} = props;
  const [initialData, setInitialData] = useState(currentReportData);
  //convert all editable timestamps into Date objects
  if (currentReportData?.sample?.uploadTime) {
    currentReportData.sample.uploadTime =
        getFirestoreTimestamp(currentReportData.sample.uploadTime).toDate();
  }
  if (currentReportData?.sample?.user?.date) {
    currentReportData.sample.user.date =
        getFirestoreTimestamp(currentReportData.sample.user.date).toDate();
  }
  const {
    register,
    watch,
    control,
    getValues,
    formState: {isSubmitting, dirtyFields},
    handleSubmit
  } = useForm(
      {
        defaultValues: {
          ...currentReportData,
        },
      });
  const [auditResult, setAuditResult] = useState(
      currentReportData.audit?.result);
  const [selectedResult, setSelectedResult] = useState('');
  const watchingAddressCountry = watch("address.country");
  const watchingBillTo = watch("billTo");
  const watchingVaccineType = watch("vaccine.type");

  const sweetAlert = withReactContent(Swal);
  const {enqueueSnackbar} = useSnackbar();
  const {userData} = useAuth();
  const editAccess = rolesViews[userData?.roles?.role]?.editSelfReport;
  const auditAccess = rolesViews[userData?.roles?.role]?.auditSelfReport;
  const [currentUser] = useAuthState(auth);
  const [editable, setEditable] = useState(false);
  const signPad = useRef(null);
  const [clientIP] = useClientIP();
  const [showFloatingEditButton, setShowFloatingEditButton] = useState(false);

  useEffect(() => {
    if (editable && window.scrollY === 0) {
      setShowFloatingEditButton(true)
    }

    function checkEditButton() {
      if (editable) {
        setShowFloatingEditButton(true);
        if ((window.innerHeight + window.scrollY)
            >= document.body.scrollHeight) {
          // you're at the bottom of the page
          setShowFloatingEditButton(false);
        }
      } else {
        setShowFloatingEditButton(false);
      }
    }

    window.addEventListener("scroll", checkEditButton);
    return () => window.removeEventListener("scroll", checkEditButton);
  }, [editable])

  const sendAuditResult = (notificationType) => {
    getDoc(doc(database.selfReports, getValues("id"))).then((snapshot) => {
      const data = snapshot.data();
      let notificationPayload = {
        selfReportId: snapshot.id,
        firstName: data.patient.fname,
        result: data.audit.result,
        ...(data.audit.rejectReason && {
          rejectMessage: rejectReasons.filter(
              (reason) => reason.name ===
                  data.audit.rejectReason)?.pop()?.message,
        }),
        uploadTime: getFirestoreTimestamp(
            data.sample.uploadTime).toDate(),
        ...(notificationType === "phone" && {phone: data.patient.phone}),
        ...(notificationType === "email" && {email: data.patient.email}),
      };
      selfReportNotify(notificationPayload).then((message) => {
        enqueueSnackbar(message, {variant: 'success'});
      }).catch((error) => {
        enqueueSnackbar(error, {variant: 'error'});
      });
    }).catch((e) => {
      enqueueSnackbar(e, {variant: 'error'});
    });
  }

  const updateResult = (submittedResult) => {
    if (!clientIP) {
      sweetAlert.fire({
        title: <p>Could not identify IP address!</p>,
        icon: "error",
        html: <p>Audit was not submitted.<br/><br/>
          Please disabled browser extensions and addons that are blocking your
          IP address, which usually include ad-blockers. Using LabPort on a
          different browser might give you better results.
        </p>,
      }).then();
      return;
    }
    if (!submittedResult) {
      enqueueSnackbar(
          "Audit result not selected!",
          {variant: 'error'});
      return;
    }
    setDoc(doc(database.selfReports, getValues("id")), {
          audit: {
            "ip": clientIP,
            "by": currentUser.uid,
            "timestamp": serverTimestamp(),
            "result": submittedResult.choice,
            ...(submittedResult.message && {rejectReason: submittedResult.name}),
          },
        }, {merge: true},
    ).then(() => {
      setAuditResult(submittedResult);
      enqueueSnackbar(
          "Audit submitted successfully!",
          {variant: 'success'});
    });
  };

  const onError = () => {
    enqueueSnackbar(
        "Please correct invalid fields.",
        {variant: 'error'});
  }

  const onSubmit = async (data) => {
    if (!clientIP) {
      sweetAlert.fire({
        title: <p>Could not identify IP address!</p>,
        icon: "error",
        html: <p>Changes were not saved.<br/><br/>
          Please disabled browser extensions and addons that are blocking your
          IP address, which usually include ad-blockers. Using LabPort on a
          different browser might give you better results.
        </p>,
      }).then();
      return;
    }
    if (signPad.current.isEmpty()) {
      enqueueSnackbar(
          "Please sign the form!",
          {variant: 'error'});
      return;
    }
    //To avoid => FirebaseError: Function setDoc() called with invalid data. Unsupported field value: undefined
    data = scrubUndefined(data);
    data.patient.phone = parsePhoneNumber(data.patient.phone)?.number;
    //convert all timestamps into Firestore Timestamp objects
    if (data?.patient?.author?.timestamp) {
      data.patient.author.timestamp =
          getFirestoreTimestamp(data.patient.author.timestamp);
    }
    if (data?.audit?.timestamp) {
      data.audit.timestamp =
          getFirestoreTimestamp(data.audit.timestamp);
    }
    if (data?.sample?.user?.date) {
      data.sample.user.date =
          getFirestoreTimestamp(new Date(getValues('sample.user.date')));
    }
    if (data?.sample?.uploadTime) {
      data.sample.uploadTime =
          getFirestoreTimestamp(new Date(getValues('sample.uploadTime')));
    }
    const signatureImage = signPad.current.toDataURL("image/svg+xml");
    const newEdit = {
      facilityId: data.facility.id,
      by: currentUser.uid,
      ip: clientIP,
      timestamp: serverTimestamp(),
      signature: signatureImage,
      editedFields: scrubUndefined(
          getDirtyValues(dirtyFields, data, initialData)),
    };
    if (dirtyFields.patient) {
      Swal.fire({
        title: 'What about future tests?',
        text: 'It looks like the patient information is different now. '
            + 'Do you want to update the patient file as well?',
        showDenyButton: true,
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Just update the Self Report',
        denyButtonText: 'Update Patient File',
      }).then((result) => {
        if (result.isConfirmed) {
          submitUpdates(data, newEdit);
        } else if (result.isDenied) {
          const addEdit = addDoc(
              collection(doc(database.patients, data.patient.id), "Edits"),
              {
                ...newEdit,
                editedFields: newEdit.editedFields.patient,
              }
          );
          const updatePatient = setDoc(doc(database.patients, data.patient.id),
              data.patient, {merge: true});
          Promise.all([
            addEdit,
            updatePatient
          ]).then(() => {
            submitUpdates(data, newEdit);
          }).catch(e => {
            enqueueSnackbar("Could not edit patient form.",
                {variant: 'error'});
            sweetAlert.fire({
              title: <p>Could not edit patient form.</p>,
              text: JSON.stringify(e),
              icon: "error",
              timer: 3000,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            }).then();
          });
        }
      });
    } else {
      submitUpdates(data, newEdit);
    }
  }

  const submitUpdates = (data, newEdit) => {
    const addEdit = addDoc(collection(doc(database.reqForms, data.id), "Edits"),
        newEdit);
    const updateReqForm = setDoc(doc(database.reqForms, data.id),
        data, {merge: true});
    Promise.all([
      addEdit,
      updateReqForm
    ]).then(() => {
      enqueueSnackbar("Self Report updated!",
          {variant: 'success'});
      setEditable(false);
      setInitialData(data); //update 'old' values if user submits again
    }).catch(e => {
      sweetAlert.fire({
        title: <p>Could not edit self report.</p>,
        text: JSON.stringify(e),
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      }).then();
    });
  }

  return (
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container spacing={3}>
          {/*Back and Edit*/}
          <Grid item xs={12}>
            <Stack
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                spacing={1}>
              <Link to={"/SelfReports"}>
                <Avatar variant={"square"}>
                  <ArrowBackIosIcon/>
                </Avatar>
              </Link>
              {editAccess &&
                  <Button
                      variant={"contained"}
                      onClick={() => {
                        if (!editable) {
                          enqueueSnackbar(
                              "Remember to save changes!",
                              {variant: "warning"});
                        }
                        setEditable(!editable)
                      }}>
                    {editable ? "Lock" : "Edit"}
                  </Button>
              }
            </Stack>
          </Grid>
          {/*Report Testing*/}
          {auditAccess &&
              <>
                {auditResult !== auditResults.pending &&
                    <Grid item xs={12}>
                      <Stack
                          direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                          spacing={1}>
                        <Button
                            onClick={() => sendAuditResult("email")}
                            variant={"contained"}
                            color={"secondary"}
                            target={"_blank"}>
                          Send Audit Result Email
                        </Button>
                        <Button
                            onClick={() => sendAuditResult("phone")}
                            variant={"contained"}
                            color={"secondary"}
                            target={"_blank"}>
                          Send Audit Result SMS
                        </Button>
                      </Stack>
                    </Grid>
                }
                {auditResult === auditResults.pending &&
                    <Grid item lg={6} sm={9} xs={12}>
                      <Stack
                          direction={"row"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={1}>
                        <FormControl fullWidth>
                          <InputLabel>{"Audit Result"}</InputLabel>
                          <Select
                              value={selectedResult}
                              onChange={(event) => setSelectedResult(
                                  event.target.value)}
                              label={"Test Result Audit"}>
                            {Object.values(auditChoices)
                            .map((item) => (
                                <MenuItem
                                    value={item}
                                    key={item.name}>
                                  {item.title}
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                            onClick={() =>
                                updateResult(selectedResult)
                            }
                            variant={"contained"}
                            color={"secondary"}
                            target={"_blank"}>
                          Confirm Audit
                        </Button>
                      </Stack>
                    </Grid>
                }
              </>
          }
          {/*Sample Image*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Image of Test Result
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MasonryImageList folderId={currentReportData.id}/>
          </Grid>
          {/*Sample Info*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Sample Info
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledSelect
                name={"sample.user.result"}
                control={control}
                items={Object.values(results)}
                label={"Reported Test Result"}
                disabled={!editable}
                required/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledDesktopDateTimePicker
                name={"sample.uploadTime"}
                control={control}
                label={"Date when test was upload"}
                inputFormat={"MM/dd/yyyy hh:mm a"}
                disabled={!editable}
                required
                minimumDate={new Date("2020-01-01")}
                disableFuture/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledDesktopDateTimePicker
                name={"sample.user.date"}
                control={control}
                label={"Date when test was taken"}
                inputFormat={"MM/dd/yyyy hh:mm a"}
                disabled={!editable}
                required
                minimumDate={new Date("2020-01-01")}
                disableFuture/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"sample.user.testType"}
                control={control}
                label={"Test Used"}
                options={testTypes}
                autoComplete={"new-password"}
                required
                disabled={!editable}/>
          </Grid>
          {/*Questionnaire*/}
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Questionnaire
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"questionnaire.symptom"}
                control={control}
                label={"Symptom"}
                options={symptoms}
                autoComplete={"new-password"}
                required
                disabled={!editable}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"questionnaire.exposure"}
                control={control}
                label={"Exposure"}
                options={exposures}
                autoComplete={"new-password"}
                required
                disabled={!editable}/>
          </Grid>
          {/*Patient Info*/}
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Patient Info
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.fname")}}
                label={"First Name"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.lname")}}
                label={"Last Name"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"patient.dob"}
                control={control}
                label={"Date of Birth"}
                autoComplete={"bday"}
                required
                inputFormat={"MM/dd/yyyy"}
                minimumDate={new Date("1900-01-01")}
                disableFuture
                disabled={!editable}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledMuiPhoneNumber
                name={"patient.phone"}
                control={control}
                label={"Phone Number"}
                autoComplete={"new-password"}
                required
                type={"tel"}
                variant={"outlined"}
                defaultCountry={'us'}
                disableAreaCodes
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.email")}}
                label={"Email"}
                autoComplete={"new-password"}
                required
                type={"email"}
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledSelect
                name={"patient.sex"}
                control={control}
                items={sexes}
                label={"Sex"}
                disabled={!editable}
                required/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledSelect
                name={"patient.race"}
                control={control}
                items={races}
                label={"Race"}
                disabled={!editable}
                required/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledSelect
                name={"patient.ethnicity"}
                control={control}
                items={ethnicities}
                label={"Ethnicity"}
                disabled={!editable}
                required/>
          </Grid>
          {facilityData?.patientCategories?.length > 0 &&
              <Grid item lg={3} sm={6} xs={12}>
                <ControlledAutocomplete
                    name={"patient.patientType"}
                    control={control}
                    label={"Patient Category"}
                    options={facilityData.patientCategories}
                    autoComplete={"new-password"}
                    freeSolo
                    required={facilityData.settings?.enforcePatientType}
                    disabled={!editable}/>
              </Grid>
          }
          {/*Employment Info*/}
          {facilityData?.settings?.collectEmployeeInfo &&
              <>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>
                    Employment Info
                  </Typography>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <TextField
                      inputProps={{...register("patient.meta.employeeId")}}
                      label={"Employee ID"}
                      required
                      variant={"outlined"}
                      disabled={!editable}
                      fullWidth/>
                </Grid>
                {facilityData?.departments?.length > 0 &&
                    <Grid item lg={4} sm={6} xs={12}>
                      <ControlledSelect
                          name={"patient.meta.employeeDept"}
                          control={control}
                          items={facilityData.departments}
                          label={"Department"}
                          disabled={!editable}
                          required/>
                    </Grid>
                }
                {facilityData?.locations?.length > 0 &&
                    <Grid item lg={4} sm={6} xs={12}>
                      <ControlledSelect
                          name={"patient.meta.employeeLocation"}
                          control={control}
                          items={facilityData.locations}
                          label={"Location"}
                          disabled={!editable}
                          required/>
                    </Grid>
                }
              </>
          }
          {/*Location Details*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Location Details
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.address.line1")}}
                label={"Address Line 1"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.address.city")}}
                label={"City"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          {(watchingAddressCountry === "United States"
                  || watchingAddressCountry === undefined) &&
              <Grid item lg={3} sm={6} xs={12}>
                <ControlledSelect
                    name={"patient.address.state"}
                    control={control}
                    items={usaStates}
                    label={"State"}
                    disabled={!editable}
                    required/>
              </Grid>
          }
          {(watchingAddressCountry === "United States"
                  || watchingAddressCountry === "Canada"
                  || watchingAddressCountry === undefined) &&
              <Grid item lg={2} sm={6} xs={12}>
                <TextField
                    inputProps={{...register("patient.address.zip")}}
                    label={"Postal Code"}
                    autoComplete={"new-password"}
                    required
                    disabled={!editable}
                    fullWidth/>
              </Grid>
          }
          <Grid item lg={4} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"patient.address.country"}
                control={control}
                label={"Country of Residence"}
                options={countries}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                variant={"outlined"}/>
          </Grid>
          {/*Billing*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Billing
            </Typography>
          </Grid>
          {/*Insurance Info*/}
          <Grid item lg={3} sm={6} xs={6}>
            <ControlledSelect
                name={"patient.billTo"}
                control={control}
                items={entities}
                label={"Bill to"}
                disabled={!editable}
                required={facilityData?.settings?.insurance}/>
          </Grid>
          {watchingBillTo === "Insurance" &&
              <>
                <Grid item xs={12}>
                  <Typography variant={"h4"}>
                    Insurance Info
                  </Typography>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <ControlledAutocomplete
                      name={"patient.insurance.carrier"}
                      control={control}
                      label={"Insurance Providers"}
                      options={insurances}
                      autoComplete={"new-password"}
                      freeSolo
                      required={facilityData?.settings?.insurance}
                      disabled={!editable}
                      variant={"outlined"}/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{
                        ...register("patient.insurance.policyNumber")
                      }}
                      label={"Policy #"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      disabled={!editable}
                      fullWidth/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{
                        ...register("patient.insurance.groupNumber")
                      }}
                      label={"Group #"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      disabled={!editable}
                      fullWidth/>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                  <TextField
                      inputProps={{
                        ...register("patient.insurance.subscriberName")
                      }}
                      label={"Subscriber Name"}
                      autoComplete={"new-password"}
                      required={facilityData?.settings?.insurance}
                      disabled={!editable}
                      fullWidth/>
                </Grid>
              </>
          }
          {/*Identification Details*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Identification Details
            </Typography>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"patient.identification.type"}
                control={control}
                label={"Identification Type"}
                options={Object.values(identificationTypes)}
                autoComplete={"new-password"}
                freeSolo
                required={facilityData?.settings?.id}
                disabled={!editable}
                variant={"outlined"}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.identification.number")}}
                label={"Identification #"}
                autoComplete={"new-password"}
                required={facilityData?.settings?.id}
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"patient.identification.exp"}
                control={control}
                label={"Expiration Date"}
                required={facilityData?.settings?.id}
                minimumDate={new Date("1900-01-01")}
                disabled={!editable}
                inputFormat={"MM/dd/yyyy"}/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"patient.identification.state"}
                control={control}
                label={"Issuance State"}
                options={states}
                autoComplete={"new-password"}
                required={facilityData?.settings?.id}
                disabled={!editable}
                variant={"outlined"}/>
          </Grid>
          {/*Vaccine Info*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Vaccine Status
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ControlledAutocomplete
                name={"patient.vaccine.type"}
                control={control}
                label={"Vaccine(s) taken"}
                options={vaccines}
                required={facilityData?.settings?.enforceVaccineCard}
                multiple
                autoComplete={"new-password"}
                autoCompleteProps={{
                  limitTags: 1,
                }}
                disabled={!editable}
                variant={"outlined"}/>
          </Grid>
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"patient.vaccine.firstDoseDate"}
                control={control}
                label={"Date of 1st Dose"}
                required={facilityData?.settings?.enforceVaccineCard}
                minimumDate={new Date("2020-01-01")}
                inputFormat={"MM/dd/yyyy"}
                disabled={!editable}
                disableFuture/>
          </Grid>
          {(watchingVaccineType?.some(v => v.includes("Moderna"))
                  || watchingVaccineType?.some(v => v.includes("Pfizer"))) &&
              <Grid item lg={2} sm={6} xs={12}>
                <ControlledDesktopDatePicker
                    name={"patient.vaccine.secondDoseDate"}
                    control={control}
                    label={"Date of 2nd Dose"}
                    required={facilityData?.settings?.enforceVaccineCard}
                    minimumDate={new Date("2020-01-01")}
                    inputFormat={"MM/dd/yyyy"}
                    disabled={!editable}
                    disableFuture/>
              </Grid>
          }
          <Grid item lg={2} sm={6} xs={12}>
            <ControlledDesktopDatePicker
                name={"patient.vaccine.boosterDate"}
                control={control}
                label={"Date of Booster"}
                required={facilityData?.settings?.enforceVaccineCard}
                minimumDate={new Date("2020-01-01")}
                inputFormat={"MM/dd/yyyy"}
                disabled={!editable}
                disableFuture/>
          </Grid>
          {/*Clinic Info*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Clinic Info
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.name")}}
                label={"Client/Facility Name"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.address")}}
                label={"Client/Facility Address"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.phone")}}
                label={"Client/Facility Phone"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.fax")}}
                label={"Client/Facility Fax"}
                autoComplete={"new-password"}
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.physician.name")}}
                label={"Physician Name"}
                autoComplete={"new-password"}
                required
                disabled={!editable}
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("facility.physician.NPI")}}
                label={"Physician NPI"}
                autoComplete={"new-password"}
                required
                InputProps={{
                  readOnly: true
                }}
                disabled={!editable}
                fullWidth/>
          </Grid>
          {/*Verification*/}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h4"}>
              Verification
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("sample.user.ip")}}
                label={"IP on Result Upload"}
                disabled
                fullWidth/>
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <TextField
                inputProps={{...register("patient.author.ip")}}
                label={"IP on Patient File"}
                disabled
                fullWidth/>
          </Grid>
          <Grid item lg={6} sm={6} xs={12}>
            <OutlinedImage
                label={"Signature on Patient File"}
                src={getValues("patient.author.signature")}
                alt={"unknown signature"}/>
          </Grid>
          {/*Sign and Submit*/}
          {editable &&
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                      value={"end"}
                      control={<Checkbox required color={"primary"}/>}
                      label="By submitting this form I am verifying that
                 the information above is correct and understand that
                 past patient records may continue to be stored by
                 LabPort for compliance purposes."
                      labelPlacement={"end"}/>
                </Grid>
                <Grid item xs={12}>
                  <CustomSignaturePad ref={signPad}/>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                      loading={isSubmitting}
                      type={"submit"}
                      variant={"contained"}
                      color={"secondary"}
                      fullWidth>
                    Edit Self Report
                  </LoadingButton>
                </Grid>
                <Grid item xs={12}
                      style={{
                        position: "fixed",
                        zIndex: "1101",
                        right: "1%",
                      }}>
                  <LoadingButton
                      sx={{
                        display: showFloatingEditButton ? "block" : "none"
                      }}
                      loading={isSubmitting}
                      type={"submit"}
                      variant={"contained"}
                      color={"secondary"}
                      fullWidth>
                    Edit Self Report
                  </LoadingButton>
                </Grid>
              </>
          }
        </Grid>
      </form>
  );
}
