import MuiAppBar from '@mui/material/AppBar';
import {
  Box,
  IconButton,
  Stack,
  styled,
  Toolbar,
  Typography,
  useMediaQuery
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountPopover from "./AccountPopover";

const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) =>
          prop !== 'expand'
          && prop !== 'mobile'
          && prop !== 'drawerWidth'
    }
)(({theme, expand, mobile, drawerWidth}) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...((expand && !mobile) && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...((!expand && !mobile) && {
    marginLeft: `calc(${theme.spacing(7)} + 1px)`,
    width: `calc(100% - (${theme.spacing(7)} + 1px))`,
  }),
}));

export default function DashboardAppBar(props) {
  const {
    expandDesktopDrawer,
    setOpenMobileDrawer,
    drawerWidth,
    toolbarTitle,
    setToolbarTitle
  } = props;
  const onMobileDrawerOpen = () => setOpenMobileDrawer(true);
  const mobileDrawer = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
      <AppBar
          elevation={1}
          position="fixed"
          expand={expandDesktopDrawer}
          mobile={mobileDrawer}
          drawerWidth={drawerWidth}>
        <Toolbar>
          {mobileDrawer && <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMobileDrawerOpen}
              edge="start"
              sx={{
                marginRight: '12px',
              }}>
            <MenuIcon/>
          </IconButton>}
          <Typography variant="h6" noWrap component="div">
            {toolbarTitle}
          </Typography>
          <Box sx={{flexGrow: 1}}/>
          <Stack direction="row" alignItems="center"
                 spacing={{xs: 0.5, sm: 1.5}}>
            {/*<NotificationsPopover/>*/}
            <AccountPopover setToolbarTitle={setToolbarTitle}/>
          </Stack>
        </Toolbar>
      </AppBar>
  );
}
