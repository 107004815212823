import {ConfigProvider, DatePicker} from "antd";
import {DATE_RANGES} from "../const/dateRanges";

const {RangePicker} = DatePicker;

export const CustomDateRangePicker = (props) => {
  const {theme, dates, rangePickerOnChange} = props;

  //partial RangePicker theme config
  ConfigProvider.config({
    theme: theme.palette.mode === "light" ? {
      primaryColor: theme.palette.primary.light,
      errorColor: theme.palette.error.light,
      warningColor: theme.palette.warning.light,
      successColor: theme.palette.success.light,
      infoColor: theme.palette.info.light,
    } : {
      primaryColor: theme.palette.primary.dark,
      errorColor: theme.palette.error.dark,
      warningColor: theme.palette.warning.dark,
      successColor: theme.palette.success.dark,
      infoColor: theme.palette.info.dark,
    }
  });

  return (
      <RangePicker
          style={{
            color: theme.palette.text.primary,
            background: theme.palette.background.default,
          }}
          popupStyle={{
            color: theme.palette.text.primary,
            background: theme.palette.background.default
          }}
          showTime={{format: 'hh:mm a'}}
          format="YYYY-MM-DD hh:mm a"
          ranges={DATE_RANGES}
          value={dates}
          onChange={rangePickerOnChange}
      />
  );
}