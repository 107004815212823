import {useEffect, useState} from "react";
import {DialogContentText} from "@mui/material";

export const ImageAgeDetail = (props) => {
  const {lastModified, uploadTime} = props;
  const [color, setColor] = useState('grey.500');
  const [fontWeight, setFontWeight] = useState('fontWeightRegular');

  useEffect(()=> {
    if(!lastModified) {
      return;
    }
    if(!uploadTime) {
      return;
    }
    const millisecondDifference = uploadTime - lastModified;
    const minuteDifference = millisecondDifference / 60000;
    const hourDifference = minuteDifference / 60;
    if(millisecondDifference < 0) {
      setColor("error.main");
      setFontWeight('fontWeightBold');
    } else if (hourDifference > 1) {
      setColor("error.main");
      setFontWeight('fontWeightBold');
    } else if (minuteDifference > 30) {
      setColor("warning.main");
      setFontWeight('fontWeightMedium');
    }
  }, [lastModified, uploadTime]);

  return (
      <DialogContentText
          color={color}
          fontWeight={fontWeight}
      >
        Image Age from Metadata: {lastModified.toLocaleString()}
      </DialogContentText>
  );
}
