export const insurances = [
    "Medicare",
    "1199 National Benefit Fund",
    "AARP",
    "Absolute Total Care",
    "Advanced Health Systems of Mississippi",
    "Aetna",
    "Aetna MEDICARE",
    "Aetna Better Health of California",
    "Aetna Better Health of Florida",
    "Aetna Better Health of Kentucky",
    "Aetna Better Health of Louisiana",
    "Aetna Better Health of Maryland",
    "Aetna Better Health of Michigan",
    "Aetna Better Health of New Jersey",
    "Aetna Better Health of Ohio",
    "Aetna Better Health of Pennsylvania",
    "Aetna Better Health of Texas",
    "Aetna Better Health of Virginia",
    "Aetna Better Health of West Virginia",
    "Aetna Bon Secours Employers Group",
    "Affinity Health Plan-New York Medicaid FFS",
    "All Savers, AMS, & United Healthcare Life Insurance Co",
    "Allied Benefit Systems",
    "Ambetter",
    "Ambetter (AR) AR Health and Wellness",
    "Ambetter (AZ) HealthNet/AZ Complete Care",
    "Ambetter (FL) Sunshine Health",
    "Ambetter (GA) Peach State Health",
    "Ambetter (IL) Illinicare",
    "Ambetter (IN) MHS",
    "Ambetter (KS) Sunflower Health Plan",
    "Ambetter (MO) Home State Health Plan",
    "Ambetter (MS) Magnolia Health",
    "Ambetter (NC) of North Carolina",
    "Ambetter (NH) Healthy Families",
    "Ambetter (NV) Silver Summit Health Plan",
    "Ambetter (OH) Buckeye Health Plan",
    "Ambetter (PA) from PA Health and Wellness",
    "Ambetter (SC) from Absolute Total Care",
    "Ambetter (TN) of Tennessee",
    "Ambetter (TX) Superior Health Plan",
    "Ambetter (WA) Coordinated Care",
    "Ameriben",
    "Amerigroup Americaid New Jersey",
    "Amerigroup Community Care-Georgia Health Families",
    "Amerigroup Community Care-Nevada Medicaid HMO",
    "Amerigroup Community Care-New Mexico Medicaid HMO",
    "Amerigroup DC Medicaid",
    "Amerigroup Duals",
    "Amerigroup Healthkeepers of Virginia Medicaid",
    "Amerigroup Iowa- Medicaid HMO",
    "Amerigroup Louisiana-Medicaid HMO",
    "Amerigroup Maryland-Medicaid HMO",
    "Amerigroup New York-Medicaid HMO",
    "Amerigroup Tennessee-Medicaid HMO",
    "Amerigroup Texas-Medicaid HMO",
    "Amerigroup Washington-Medicaid HMO",
    "Amerihealth Administrators",
    "Amerihealth DC-MEDICARE HMO",
    "Amerihealth Louisiana-LA Care",
    "Amerihealth New Jersey-HMO",
    "Amerihealth New Jersey-PPO",
    "Amerihealth-Caritas Delaware",
    "Amerihealth-Crown Cork & Seal",
    "Amida Care",
    "Anthem",
    "Anthem Blue Cross Blue Shield Colorado",
    "Anthem Blue Cross Blue Shield Colorado-Federal Employee Plan",
    "Anthem Blue Cross Blue Shield Georgia",
    "Anthem Blue Cross Blue Shield Indiana",
    "Anthem Blue Cross Blue Shield Kentucky",
    "Anthem Blue Cross Blue Shield Kentucky-Senior Advantage",
    "Anthem Blue Cross Blue Shield Nevada",
    "Anthem Blue Cross Blue Shield New Hampshire",
    "Anthem Blue Cross Blue Shield New York",
    "Anthem Blue Cross Blue Shield of Conneticut",
    "Anthem Blue Cross Blue Shield of Georgia",
    "Anthem Blue Cross Blue Shield of Wisconsin",
    "Anthem Blue Cross Blue Shield Ohio",
    "Anthem Blue Cross Blue Shield St Louis",
    "Anthem Blue Cross Blue Shield Virginia",
    "Anthem Blue Cross California-HMO",
    "Anthem Blue Cross California-PPO",
    "Anthem Blue Cross Medi-Cal",
    "Anthem Blue Georgia",
    "Anthem Federal Blue Cross Blue Shield Georgia",
    "Anthem Federal Blue Cross Blue Shield Kentucky",
    "Anthem Federal Blue Cross Blue Shield Maine",
    "Anthem Federal Blue Cross Blue Shield Nevada",
    "Anthem Federal Blue Cross Blue Shield New York",
    "Anthem Federal Blue Cross Blue Shield Ohio",
    "Anthem Federal Blue Cross Blue Shield Virginia",
    "Anthem Healthkeepers",
    "Anthem Healthy Indiana",
    "Anthem Hoosier Healthwise of Indiana",
    "Anthem Kentucky Medicaid",
    "Benefit Administrators Systems",
    "Blue Choice Health Plan",
    "Blue Cross Blue Shield Alabama",
    "Blue Cross Blue Shield Alaska-Premera",
    "Blue Cross Blue Shield Arizona",
    "Blue Cross Blue Shield Arkansas",
    "Blue Cross Blue Shield Arkansas Health Advantage",
    "Blue Cross Blue Shield Delaware",
    "Blue Cross Blue Shield Florida",
    "Blue Cross Blue Shield Illinois",
    "Blue Cross Blue Shield Illinois-Medicaid",
    "Blue Cross Blue Shield Kansas City",
    "Blue Cross Blue Shield Louisiana",
    "Blue Cross Blue Shield Louisiana-Community Blue",
    "Blue Cross Blue Shield Massachusetts",
    "Blue Cross Blue Shield Minnesota",
    "Blue Cross Blue Shield Mississippi",
    "Blue Cross Blue Shield Montana",
    "Blue Cross Blue Shield New Jersey-Horizon",
    "Blue Cross Blue Shield New Mexico",
    "Blue Cross Blue Shield North Carolina",
    "Blue Cross Blue Shield of DC-Carefirst",
    "Blue Cross Blue Shield of Maryland-Carefirst",
    "Blue Cross Blue Shield of Minnesota",
    "Blue Cross Blue Shield Pennsylvania Freedom Blue",
    "Blue Cross Blue Shield South Carolina",
    "Blue Cross Blue Shield Tennessee",
    "Blue Cross Blue Shield Texas",
    "Blue Cross Blue Shield Texas-Exchange",
    "Blue Cross Blue Shield Vermont",
    "Blue Cross Blue Shield West Virginia-Mountain State",
    "Blue Cross Idaho",
    "Blue Cross Utah",
    "Blue Cross Wyoming",
    "Blue Shield Blue Advantage of Tennessee",
    "Blue Shield California HMO/PPO Essential Worker",
    "Blue Shield California-HMO",
    "Blue Shield California-PPO",
    "Blue Shield California-PPO Covered California",
    "Blue Shield Iowa-Alliance Select",
    "Blue Shield Iowa-Wellmark",
    "Blue Shield Kansas City",
    "Blue Shield Michigan",
    "Blue Shield Missouri-Alliance Choice",
    "Blue Shield Nebraska",
    "Blue Shield New York-Central New York",
    "Blue Shield New York-Utica/Watertow",
    "Blue Shield of Idaho",
    "Blue Shield Oklahoma",
    "Blues Alabama-Blue Advantage",
    "Blues New Jersey-Horizon My Way",
    "Blues North Carolina-Blue MEDICARE PPO/HMO",
    "Blues South Carolina-Healthy Blue SC",
    "Bright Health",
    "Buckeye Community Health Plan",
    "Capital District Health Plan",
    "Capital Health Plan",
    "Care Oregon OHP",
    "Carefirst Blue Choice",
    "Caresource Georgia",
    "Caresource Kentucky-Just 4 Me",
    "Caresource Michigan-MEDICARE Advantage",
    "Caresource Ohio",
    "Central California Alliance",
    "Champva/Veterans Admin/Dept of VA Affairs",
    "Christus Health Plan Exchange",
    "Cigna",
    "Cigna-HealthSpring",
    "Cigna-HealthSpring Arizona",
    "Clear Health Alliance",
    "Community Health Choice",
    "Community Health Choice INC",
    "Community Health Plan of Washington",
    "Community Health Plan of Washington MEDICARE Advantage",
    "Cook Childrens Health Plan",
    "Coordinated Care-Washington Medicaid",
    "Coresource",
    "Coresource-Aetna Signature Admin PPO",
    "County Care",
    "Coventry-Florida MEDICARE",
    "Coventry-Health America MEDICARE FFS",
    "Coventry-MEDICARE Advantra",
    "Culinary Health Fund",
    "Deseret Mutual Benefit Administrators Plan",
    "Empire Plan-United Healthcare",
    "Essence Healthcare",
    "Excellus Blue Cross Blue Shield",
    "Fallon Community Health Plan",
    "Federal Blue Cross Blue Shield Alabama",
    "Federal Blue Cross Blue Shield Arizona",
    "Federal Blue Cross Blue Shield Florida",
    "Federal Blue Cross Blue Shield Louisiana",
    "Federal Blue Cross Blue Shield Massachusetts",
    "Federal Blue Cross Blue Shield New Jersey",
    "Federal Blue Cross Blue Shield North Carolina",
    "Federal Blue Cross Blue Shield South Dakota",
    "Federal Blue Cross Blue Shield Tennessee",
    "Federal Blue Cross Blue Shield Texas",
    "Federal Blue Shield California",
    "Federal Blues DC",
    "Federal Blues Maryland-Carefirst",
    "Federal Employee Plan of Connecticut",
    "Federal Employee Plan of Utah",
    "Federal Employee Plan of West Virginia",
    "Fidelis Care Medicaid HMO",
    "First Choice",
    "GEHA",
    "Geisinger Health Plan",
    "Gilsbar",
    "Golden Rule-UHC",
    "Golden Triangle Physician Alliance",
    "Great West Healthcare",
    "Group and Pension Administrators",
    "Harvard Pilgrim Health",
    "Health Alliance",
    "Health Alliance Plan",
    "Health Alliance-Cigna",
    "Health Choice",
    "Health Choice-Connecticut",
    "Health Choice-Utah",
    "Health Net",
    "Health Options",
    "Health Partners-Cigna",
    "Health plan of Nevada",
    "Health Plan of Nevada HMO",
    "Health Plan of Nevada Medicaid",
    "Health Plan-THP Ins Co",
    "Health Plus Amerigroup New York-Medicaid",
    "Health Team Advantage",
    "Healthcare Management Administrator",
    "HealthFirst",
    "Healthnet California-Covered California",
    "Healthnet Medi-Cal",
    "Healthnet of California",
    "Healthnet PPO-California",
    "Healthscope Benefits",
    "Highmark Blue Shield of Pennsylvania",
    "HIP",
    "Hometown Health Providers PPO",
    "Humana",
    "Humana Caresource Medicaid HMO",
    "Humana MEDICARE Advantage",
    "iCare Independent Care Health Plan",
    "iCare Independent Care Medicaid",
    "Illinicare Health Plan",
    "Independence Administrators",
    "Independence Blue Cross-Personal Choice",
    "Independence Blue Cross-PPO Exchange",
    "Kaiser Foundation Health",
    "Kaiser Health Plan of Washington",
    "Kaiser Permanente",
    "Key Benefit Administrators",
    "Keystone East",
    "Keystone First Community Health Choice",
    "Keystone Health Plan East",
    "Keystone VIP Choice MEDICARE Plan",
    "LA Care Health Plan",
    "Louisiana Healthcare Connections",
    "Magellan Complete Care",
    "Magna Care",
    "Magnolia Health Plan",
    "Mail Handlers Benefit Plan",
    "Mamsi Life and Health",
    "Managed Health Services-Indiana",
    "Maryland Physicians Care",
    "MedCost",
    "Medi-Cal California",
    "Medica Choice Claims",
    "Medicaid Alabama",
    "Medicaid Alaska",
    "Medicaid Arizona-AHCCCS",
    "Medicaid Arkansas",
    "Medicaid Colorado",
    "Medicaid Connecticut",
    "Medicaid DC",
    "Medicaid Delaware",
    "Medicaid Florida",
    "Medicaid Georgia",
    "Medicaid Idaho",
    "Medicaid Illinois",
    "Medicaid Indiana",
    "Medicaid Iowa-Enterprise",
    "Medicaid Kansas",
    "Medicaid Kentucky",
    "Medicaid Louisiana",
    "Medicaid Maine",
    "Medicaid Maryland",
    "Medicaid Massachusetts",
    "Medicaid Michigan",
    "Medicaid Minnesota",
    "Medicaid Mississippi",
    "Medicaid Missouri",
    "Medicaid Montana",
    "Medicaid Nebraska",
    "Medicaid Nevada",
    "Medicaid New Hampshire",
    "Medicaid New Jersey",
    "Medicaid New Mexico",
    "Medicaid New York",
    "Medicaid North Carolina",
    "Medicaid North Dakota",
    "Medicaid Ohio",
    "Medicaid Oklahoma",
    "Medicaid Oregon (OMAP)",
    "Medicaid Pennsylvania",
    "Medicaid Rhode Island",
    "Medicaid South Carolina",
    "Medicaid South Dakota",
    "Medicaid Texas",
    "Medicaid Utah",
    "Medicaid Vermont",
    "Medicaid Virginia",
    "Medicaid Washington",
    "Medicaid West Virginia",
    "Medicaid Wisconsin",
    "Medicaid Wyoming",
    "Medical Mutual of Ohio",
    "MediGold",
    "Medstar Select",
    "Medwise Healthy Indiana Plan",
    "Megellan Healthcare Inc",
    "Meridian Advantage Plan of Illinois",
    "Meridian Health Plan-Medicaid HMO",
    "Meritain Health",
    "Metro Plus Health Plan",
    "Moda Health ODH Health Plan",
    "Molina California",
    "Molina California-Marketplace",
    "Molina California-MEDICARE HMO",
    "Molina Florida-Exchange",
    "Molina Florida-Medicaid HMO",
    "Molina Florida-MEDICARE HMO",
    "Molina Idaho-Medicaid HMO",
    "Molina Idaho-MEDICARE HMO",
    "Molina Illinois-Medicaid HMO",
    "Molina Illinois-MEDICARE HMO",
    "Molina Michigan-Exchange",
    "Molina Michigan-Medicaid HMO",
    "Molina Michigan-MEDICARE HMO",
    "Molina Mississippi-Medicaid HMO",
    "Molina New Mexico-Marketplace",
    "Molina New Mexico-MEDICARE HMO",
    "Molina New York-Medicaid HMO",
    "Molina Ohio-Marketplace",
    "Molina Ohio-Medicaid HMO",
    "Molina Ohio-MEDICARE HMO",
    "Molina Oregon & Washington-Exchange",
    "Molina Oregon & Washington-Medicaid HMO",
    "Molina Oregon & Washington-MEDICARE HMO",
    "Molina South Carolina-Medicaid HMO",
    "Molina South Carolina-MEDICARE HMO",
    "Molina Texas-Exchange",
    "Molina Texas-Medicaid HMO",
    "Molina Texas-MEDICARE HMO",
    "Molina Utah-Medicaid HMO",
    "Molina Utah-MEDICARE HMO",
    "Molina Wisconsin-Marketplace",
    "Molina Wisconsin-Medicaid HMO",
    "Molina Wisconsin-MEDICARE HMO",
    "MVP Health Care New York",
    "MVP Health Care-New York",
    "National Association of Letter Carriers",
    "NCAS Carefirst Administrators",
    "Nevada Department of Corrections-Hometown Health",
    "Nevada Medicaid & Check Up",
    "North American Administrators",
    "Optimum Choice United Healthcare",
    "Oscar Health-New York",
    "OSU Health Plan-Coresource",
    "Oxford Health",
    "Pacificsource",
    "Paramount Health Care",
    "Parkland Healthfirst Network",
    "Partnership Healthplan of California",
    "Passport Health Plan",
    "Peach State Health Plan",
    "Pennsylvania Employees Benefit Trust Fund",
    "Philadelphia Life",
    "Preferred Care Partners",
    "Premera Blue Cross Washington",
    "Priority Health",
    "Priority Partners",
    "Providence Health Assurance",
    "Qualchoice of Arkansas",
    "Regence Blue Cross Blue Shield Medical Advantage",
    "Regence Blue Cross Blue Shield of Oregon",
    "Regence Blue Shield of Washington",
    "Regence Federal Blue Shield Washington",
    "Regence Group Administrators",
    "Secure Horizons HMO of Colorado",
    "Secure Horizons HMO of Nevada",
    "Secure Horizons HMO of Oklahoma & Texas",
    "Secure Horizons HMO of Oregon",
    "Secure Horizons HMO of Washington",
    "Secure Horizons of Arizona",
    "Secure Horizons Private Fee For Service",
    "Select Health",
    "Select Health of South Carolina",
    "State Health Plan Network",
    "State Health Plan PPO-Blues Options",
    "State of California Benefits",
    "Strategic Resource Company",
    "Silver Summit Health Plan",
    "Sunshine State Health Plan",
    "Superior Health Plan-CHIP",
    "Superior Health Plan-Medicaid HMO",
    "Tricare Life",
    "Tricare-East/Humana Military",
    "Tricare-West/Healthnet Federal Services",
    "True Blue",
    "Trustmark",
    "Tufts Health Plan",
    "Ucare Medicaid Plan",
    "UMR",
    "UMR-United Healthcare Shared Services",
    "UniCare",
    "United Healthcare",
    "United Healthcare Community MEDICARE Plan",
    "United Healthcare Community Plan of Arizona",
    "United Healthcare Community Plan of Florida",
    "United Healthcare Community Plan of Iowa-Hawki & Medicaid",
    "United Healthcare Community Plan of Kansas",
    "United Healthcare Community Plan of Louisiana",
    "United Healthcare Community Plan of Maryland",
    "United Healthcare Community Plan of Michigan",
    "United Healthcare Community Plan of Mississippi",
    "United Healthcare Community Plan of Missouri",
    "United Healthcare Community Plan of New Jersey",
    "United Healthcare Community Plan of New York",
    "United Healthcare Community Plan of Ohio",
    "United Healthcare Community Plan of Pennsylvania",
    "United Healthcare Community Plan of Star Plus",
    "United Healthcare Community Plan of Tennessee",
    "United Healthcare Community Plan of Texas (Star and Chip Programs)",
    "United Healthcare Community Plan of Virginia",
    "United Healthcare Community Plan of Washington",
    "United Healthcare Community Plan Wisconsin Medicaid",
    "United Healthcare Dual Complete MEDICARE-Florida",
    "United Healthcare Dual Complete MEDICARE-New Jersey",
    "United Healthcare Dual Complete MEDICARE-New York",
    "United Healthcare Dual Complete MEDICARE-North Carolina",
    "United Healthcare Dual Complete MEDICARE-Tennessee",
    "United Healthcare Dual Complete MEDICARE-Texas",
    "United Healthcare Dual Complete MEDICARE-Washington",
    "United Healthcare Integrated Services",
    "United Healthcare of California",
    "United Healthcare of Oklahoma",
    "United Healthcare of Washington",
    "United Healthcare of the River Valley",
    "United Healthcare Student Resources",
    "United Healthcare-AARP MEDICARE Plans",
    "University of Pittsburgh Medical Center Commercial Plan",
    "United States Uniformed Services",
    "US Family Health Plan Johns Hopkins",
    "Value Options-Maryland MHA",
    "Virginia Premier-Medicaid",
    "Virginia United Dual Complete MEDICARE",
    "Violet Overn",
    "Viva Health Inc",
    "Viva MEDICARE Plus",
    "Web-TPA",
    "Wellcare",
    "Wellcare Medicaid Plan of New York Inc",
    "Wellcare MEDICARE Plan of New York Inc",
    "Wellcare of Georgia",
    "Wellcare of Kentucky",
    "Wellcare of New Jersey",
    "Wellcare of South Carolina",
    "Wellcare-Harmony Health Plans",
    "Wellmed USMD and MCNT Dallas"
];
