import {database} from "../components/firebaseConfig";
import {
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import {rolesViews} from "../const/roles";
import {DATE_RANGES} from "../const/dateRanges";

export const restrictDateRange = (role, route, dates) => {
  const restrictions = rolesViews[role]?.timeRestrictions
      && rolesViews[role]?.timeRestrictions[route];
  if (restrictions?.length > 0) {
    return DATE_RANGES[restrictions[0]];
  } else {
    return dates;
  }
}

export const facilityRequirePayment = (facilityData) => {
  return (facilityData.settings?.antigen || facilityData.settings?.pcr);
}

export const patientHasAntigenCredit = (patient) => {
  return (patient?.credit && patient.credit?.antigen > 0);
}

export const patientHasPCRCredit = (patient) => {
  return (patient?.credit && patient.credit?.pcr > 0);
}

export const syncCreditToCheckin = (patientId) => {
  getDocs(query(database.checkedIn,
      where("patient.id", "==", patientId)
  )).then((checkInDocuments) => {
    getDoc(doc(database.patients, patientId)).then((patient) => {
      checkInDocuments.forEach((checkInDoc) => {
        updateDoc(checkInDoc.ref, `patient.credit`, patient.data().credit).then();
      });
    });
  });
}

export const decrementCreditByOne = (patientId, typeOfCredit) => {
  //decrement on checkin instances + decrement on patient side
  getDoc(doc(database.patients, patientId)).then((patient) => {
    updateDoc(patient.ref, `credit.${typeOfCredit}`,
        patient.data().credit[typeOfCredit] - 1).then(() => {
      syncCreditToCheckin(patientId);
    });
  });
}

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export const getDirtyValues = (
    dirtyFields,
    allNewValues,
    allOriginalValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return {new: allNewValues, old: allOriginalValues};
  }

  // Here, we have an object.
  return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        getDirtyValues(
            dirtyFields[key],
            allNewValues[key],
            Boolean(allOriginalValues)
                ? allOriginalValues[key] === undefined
                    ? "" : allOriginalValues[key] : "")
      ])
  );
};

//https://stackoverflow.com/a/57625661/11466100
export const scrubUndefined = (object) => {
  if (Array.isArray(object)) {
    return object
    .map(v => (v && typeof v === 'object') ? scrubUndefined(v) : v)
    .filter(v => !(v === undefined));
  } else {
    return Object.entries(object)
    .map(([k, v]) => [k, v && typeof v === 'object' ? scrubUndefined(v) : v])
    .reduce((a, [k, v]) => (v === undefined ? a : (a[k]=v, a)), {});
  }
}

export const getFirestoreTimestamp = (timestamp) => {
  if(!Boolean(timestamp)){
    return Timestamp.now();
  }
  if(isValidDate(timestamp)) {
    return Timestamp.fromDate(timestamp);
  }
  return new Timestamp(timestamp.seconds, timestamp.nanoseconds);
}

//https://stackoverflow.com/a/44198641
function isValidDate(date) {
  return date
      && Object.prototype.toString.call(date) === "[object Date]"
      && !isNaN(date);
}
