import {useEffect, useState,} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  InputAdornment,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {rolesViews} from "../const/roles";
import axios from "axios";
import {AccountCircle} from "@mui/icons-material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {envConfig} from "../envConfig";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../components/firebaseConfig";
import Page from "../components/Page";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

export default function UserAccess() {
  const INTERNAL_API_URL = envConfig.currentAPI + "internal";
  const sweetAlert = withReactContent(Swal);
  const [clearSearch, setClearSearch] = useState(false);
  const [currentPageUsers, setCurrentPageUsers] = useState({users: []});
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [facilities, setFacilities] = useState([]);
  const [currentUserToken, setCurrentUserToken] = useState("");
  const [currentUser] = useAuthState(auth);

  const fetchPage = (fetchPageData) => {
    setClearSearch(false)
    if (!fetchPageData.currentUserToken) {
      console.log("token is empty")
      return;
    } else {
      setLoading(true)
      let data = {
        pageSize: pageSize,
      }
      fetchPageData.nextPageToken
      && (data.pageToken = fetchPageData.nextPageToken)
      return axios.post(INTERNAL_API_URL + '/users', data,
          {
            headers: {
              auth: fetchPageData.currentUserToken
            }
          }).then(response => {
        setLoading(false)
        setCurrentPageUsers(response.data)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
    }
  }

  const fetchFacilities = (data) => {
    return axios.post(INTERNAL_API_URL + '/facilities', {}, {
      headers: {
        auth: data.currentUserToken
      }
    }).then(response => {
      setFacilities(response?.data?.facilities)
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    async function initialLoad() {
      setLoading(true)
      await currentUser.getIdToken(true).then(async (token) => {
        setCurrentUserToken(token);
        await fetchFacilities({
          currentUserToken: token
        });
        await fetchPage({
          currentUserToken: token
        });
        setLoading(false);
      })
    }

    initialLoad()
  }, [pageSize])

  const getUser = (e) => {
    e.preventDefault()
    setClearSearch(true)
    if (!e.target[0].value) {
      sweetAlert.fire({
        title: <p>Enter an email to search</p>,
        icon: "error",
        toast: true,
        position: 'bottom-end',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
      return;
    }
    setLoading(true)
    return axios.post(INTERNAL_API_URL + '/user', {
          command: "get",
          email: e.target[0].value
        },
        {
          headers: {
            auth: currentUserToken
          }
        }).then(response => {
      setLoading(false)
      setCurrentPageUsers(response.data)
    }).catch((err) => {
      sweetAlert.fire({
        title: <p>{err?.response?.data?.status ?? "Could not fetch user."}</p>,
        icon: "error",
        toast: true,
        position: 'bottom-end',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
      setLoading(false)
    })
  }

  const updateRowsValues = (newState) => {

    // {
    //     key : disabled | facilities | role,
    //     value : value
    //     row : row
    // }

    let newUsers = currentPageUsers.users
    newUsers[currentPageUsers.users.indexOf(
        newState.row)][`${newState.key}`] = newState.value
    //newUsers[newState.index][newState.key] = newState.value

    setCurrentPageUsers({
      nextPage: currentPageUsers.nextPage,
      users: [...newUsers]
    })
  }

  return (
      <Page title={"LabPort | User Access"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form onSubmit={getUser} id={"searchForm"}>
              <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                {loading && <LinearProgress/>}
                <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                <TextField
                    label="Search users" variant="standard"
                    placeholder={"email@domain.com"}
                    sx={{width: "50%"}}
                    id={"emailLookup"}
                    type="email"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                            <Button
                                type={"submit"}
                            >Search</Button>
                            <Button
                                onClick={() => {
                                  fetchPage({
                                    currentUserToken: currentUserToken,
                                    pageSize: pageSize,
                                  })
                                }}
                                type={"reset"}
                                sx={{
                                  color: 'action.active',
                                  display: clearSearch ? "" : "none",
                                  minWidth: "10px",
                                  padding: "2px"
                                }}>
                              <ClearIcon/>
                            </Button>
                          </InputAdornment>
                      ),
                    }}
                />
              </Box>
            </form>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
                sx={{
                  maxHeight: 625,
                }}
            >
              <Table
                  aria-labelledby="tableTitle"
                  stickyHeader
              >
                <TableHead>
                  <TableRow>

                    {/*{currentPageUsers && Object.keys(currentPageUsers.users[0]).map((col,index)=>{*/}
                    {/*    if(col!=="uid")*/}
                    {/*    return (*/}
                    {/*        <TableCell>*/}
                    {/*            {col}*/}
                    {/*        </TableCell>*/}
                    {/*    )*/}
                    {/*})}*/}

                    <TableCell
                        width={2}
                    >
                      Active
                    </TableCell>
                    <TableCell
                        width={2}>
                      Email
                    </TableCell>
                    <TableCell
                    >
                      Role
                    </TableCell>
                    <TableCell
                    >
                      Facilities
                    </TableCell>
                    <TableCell>
                      Last Sign In Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                      currentPageUsers && currentPageUsers.users?.length > 0
                      && currentPageUsers.users.map((row, index) => {
                        return (
                            <TableRow
                                hover
                                key={index}
                            >
                              <TableCell

                              >
                                <Switch
                                    checked={!row.disabled}
                                    onChange={(e, value) => {
                                      setLoading(true)
                                      return axios.post(INTERNAL_API_URL + '/user',
                                          {
                                            command: "modify",
                                            uid: row.uid,
                                            active: !value
                                          },
                                          {
                                            headers: {
                                              auth: currentUserToken
                                            }
                                          }).then(response => {
                                        setLoading(false)
                                        sweetAlert.fire({
                                          title: <p>{value
                                              ? `Activated ${row.email} account.`
                                              : `Disabled ${row.email} account`}</p>,
                                          icon: "success",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });
                                        //change facilities on the front end

                                        updateRowsValues({
                                          key: "disabled",
                                          value: !value,
                                          row: row
                                        })
                                        // let newUsers = currentPageUsers.users
                                        // newUsers[currentPageUsers.users.indexOf(row)].disabled = !value
                                        // setCurrentPageUsers({
                                        //     nextPage : currentPageUsers.nextPage,
                                        //     users: [...newUsers]
                                        // })

                                      }).catch((err) => {
                                        console.log(err)
                                        sweetAlert.fire({
                                          title: <p>{err?.response?.data?.status
                                              ?? "Could not update user."}</p>,
                                          icon: "error",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });
                                        setLoading(false)
                                      })
                                    }}
                                />
                              </TableCell>
                              <TableCell>
                                {row.email}
                              </TableCell>
                              <TableCell>
                                <Autocomplete
                                    disableClearable
                                    value={rolesViews.display[row.role]}
                                    sx={{width: "100%"}}
                                    onChange={(e, value) => {

                                      setLoading(true)
                                      axios.post(INTERNAL_API_URL + '/user', {
                                            command: "modify",
                                            uid: row.uid,
                                            role: Object.keys(rolesViews.display).find(
                                                key => rolesViews.display[key]
                                                    === value)
                                          },
                                          {
                                            headers: {
                                              auth: currentUserToken
                                            }
                                          }).then(response => {
                                        setLoading(false)

                                        //change facilities on the front end
                                        sweetAlert.fire({
                                          title: <p>Changed {row.email} role
                                            from {rolesViews.display[currentPageUsers.users[currentPageUsers.users.indexOf(
                                                row)].role]} to {value}</p>,
                                          icon: "success",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });

                                        // let newUsers = currentPageUsers.users
                                        // newUsers[currentPageUsers.users.indexOf(row)].role = Object.keys(rolesViews.display).find(key => rolesViews.display[key] === value)
                                        // setCurrentPageUsers({
                                        //     nextPage : currentPageUsers.nextPage,
                                        //     users: [...newUsers]
                                        // })

                                        updateRowsValues({
                                          key: "role",
                                          value: Object.keys(
                                              rolesViews.display).find(
                                              key => rolesViews.display[key]
                                                  === value),
                                          row: row
                                        })

                                      }).catch((err) => {
                                        console.log(err)
                                        sweetAlert.fire({
                                          title: <p>{err?.response?.data?.status
                                              ?? "Could not update user."}</p>,
                                          icon: "error",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });
                                        setLoading(false)
                                      })
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{
                                              width: "150px"
                                            }}
                                            variant={"standard"} {...params}
                                            label={"Role"}/>
                                    )}
                                    options={Object.values(rolesViews.display)}/>
                              </TableCell>
                              <TableCell
                              >
                                <Autocomplete
                                    multiple
                                    disableClearable
                                    options={facilities}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option,
                                        value) => option.id === value.id}

                                    value={row.facilities}

                                    autoComplete
                                    getOptionLabel={(option) => option.facilityName}

                                    onChange={(e, value) => {
                                      if (value.length < 1) {
                                        sweetAlert.fire({
                                          title:
                                              <p>{"At least one facility needs to be selected."}<br/>You
                                                can de-activate a user if you want
                                                to disable their account.</p>,
                                          icon: "error",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 5000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });
                                        return;
                                      }

                                      setLoading(true)

                                      return axios.post(INTERNAL_API_URL + '/user',
                                          {
                                            command: "modify",
                                            uid: row.uid,
                                            facilities: value
                                          },
                                          {
                                            headers: {
                                              auth: currentUserToken
                                            }
                                          }).then(response => {
                                        setLoading(false)

                                        sweetAlert.fire({
                                          title: <p>Edited {row.email} facility
                                            access</p>,
                                          icon: "success",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });

                                        //change facilities on the front end
                                        // let newUsers = currentPageUsers.users
                                        // newUsers[currentPageUsers.users.indexOf(row)].facilities = value
                                        // setCurrentPageUsers({
                                        //     nextPage : currentPageUsers.nextPage,
                                        //     users: [...newUsers]
                                        // })

                                        updateRowsValues({
                                          key: "facilities",
                                          value: value,
                                          row: row
                                        })

                                      }).catch((err) => {
                                        console.log(err)
                                        sweetAlert.fire({
                                          title: <p>{err?.response?.data?.status
                                              ?? "Could not update user."}</p>,
                                          icon: "error",
                                          toast: true,
                                          position: 'bottom-end',
                                          timer: 3000,
                                          showCancelButton: false,
                                          showConfirmButton: false,
                                          timerProgressBar: true,
                                          didOpen: (toast) => {
                                            toast.addEventListener('mouseenter',
                                                Swal.stopTimer)
                                            toast.addEventListener('mouseleave',
                                                Swal.resumeTimer)
                                          }
                                        });
                                        setLoading(false)
                                      })

                                    }}

                                    renderTags={((value, getTagProps) => {
                                      return <>
                                        <Chip
                                            variant="outlined"
                                            label={value[0].facilityName}
                                            size="small"
                                            {...getTagProps(0)}

                                        />
                                        {value.length >= 2 ? `and ${value.length
                                        - 1} more` : ""}
                                      </>
                                    })}

                                    renderOption={(props, option, {selected}) => (
                                        <li {...props}>
                                          <Checkbox
                                              icon={icon}
                                              checkedIcon={checkedIcon}
                                              style={{marginRight: 8}}
                                              checked={selected}
                                              onChange={(e, value) => {
                                                // let newUsers = currentPageUsers.users
                                                //
                                                // if(value) {
                                                //     //add facility to thier access on the front end
                                                //     newUsers[currentPageUsers.users.indexOf(row)].facilities.push(option)
                                                // }
                                                // else {
                                                //     //remove facility access on the front end
                                                //     newUsers[currentPageUsers.users.indexOf(row)].facilities.filter((e)=>{
                                                //         return e!==option
                                                //     })
                                                //     newUsers[currentPageUsers.users.indexOf(row)].facilities = newUsers[currentPageUsers.users.indexOf(row)].facilities.filter((e)=>{
                                                //         return e!==option
                                                //     })
                                                // }

                                                // setCurrentPageUsers({
                                                //     nextPageToken : currentPageUsers.nextPageToken,
                                                //     users: [...newUsers]
                                                // })

                                              }}
                                          />
                                          {option.facilityName}
                                        </li>
                                    )}
                                    style={{width: "380px"}}
                                    renderInput={(params) => (
                                        <TextField variant={"standard"} {...params}
                                                   label={"Access"}/>
                                    )}
                                />
                              </TableCell>
                              <TableCell

                              >
                                {new Date(Date.parse(
                                    row.lastSignInTime)).toLocaleString()}
                              </TableCell>

                              {/*<TableCell>*/}
                              {/*    {JSON.stringify(row)}*/}
                              {/*</TableCell>*/}

                            </TableRow>
                        )
                      })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[2, 5, 10, 15, 40, 100]}
                count={-1}
                page={page}
                rowsPerPage={pageSize}
                backIconButtonProps={
                  {
                    sx: {
                      display: "none"
                    }
                  }
                }
                nextIconButtonProps={{
                  sx: {
                    display: currentPageUsers.nextPage ? "" : "none"
                  }
                }}
                onRowsPerPageChange={(event) => {
                  setPageSize(parseInt(event.target.value, 10));
                  setPage(0);
                }}
                onPageChange={(event, number) => {
                  fetchPage({
                    currentUserToken: currentUserToken,
                    pageSize: pageSize,
                    nextPageToken: currentPageUsers.nextPage
                  })
                }}
            />
          </Grid>
        </Grid>
      </Page>
  );
}
