import {useEffect, useState} from 'react';

export const useClientIP = () => {
  const [clientIP, setClientIP] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
      data.text().then(response => {
        setClientIP(Object.fromEntries(
            response.trim().split("\n").map(e => e.split("="))).ip);
        setLoading(false);
      }).catch(e => {
        console.log(e);
        setLoading(false);
      });
    }).catch(e => {
      console.log(e);
      setLoading(false);
    });
  }, []);

  return [clientIP, loading];
}
