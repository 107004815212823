import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import {useTheme} from "@mui/material";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import useStorage from "../../hooks/useStorage";
import {CustomDateRangePicker} from "../DateRangePicker";

export default function CustomDataGrid(props) {
  const {
    dates,
    rangePickerOnChange,
    localVar,
    customToolBars,
    initialColumnVisibility,
  } = props;
  const theme = useTheme();

  //filter logic
  const filterValidator = (newModel) => {
    //needs to make sure items is an array
    if (!Array.isArray(newModel?.items)) {
      return false;
    }
    //items.length can't be > 1 (MIT version limitation)
    if (newModel.items.length > 1) {
      return false;
    }
    //each item in items needs to be an object {}
    //each item needs to have an id key/value pair
    return !(newModel.items.length > 0 && !newModel.items[0].id);
  };
  const [filterModel, setFilterModel] = useStorage({
    key: localVar + "Filter",
    initialValue: {items: []},
    type: 'object',
    validator: filterValidator,
  });
  const handleFilterChange = (newModel) => setFilterModel(newModel);

  //sort logic
  const sortValidator = (newModel) => {
    //needs to make sure sortModel is an array
    if (!Array.isArray(newModel)) {
      return false;
    }
    if (newModel.length === 0) {
      return true;
    }
    //each element needs to have their field key with value in props.columns[].field
    let failed = false;
    newModel.forEach((item) => {
      props.columns?.forEach((column) => {
        if (item.field === column.field) {
          failed = true;
        }
      })
    });
    return failed;
  };
  const [sortModel, setSortModel] = useStorage({
    key: localVar + "Sort",
    initialValue: [{"field": 'Date', "sort": 'desc'}],
    type: 'array',
    validator: sortValidator,
  });
  const handleSortChange = (newModel) => setSortModel(newModel);

  //column visibility logic
  const [columnModel, setColumnModel] = useStorage({
    key: localVar + "Column",
    initialValue: initialColumnVisibility ?? {},
    type: 'object',
  });
  const handleColumnChange = (newModel) => setColumnModel(newModel);

  const CustomToolbar = () => {
    return (
        <GridToolbarContainer style={{display: 'flex', flexWrap: 'wrap'}}>
          {dates &&
              <CustomDateRangePicker
                  theme={theme}
                  dates={dates}
                  rangePickerOnChange={rangePickerOnChange}
              />
          }
          {customToolBars}
          <GridToolbarColumnsButton/>
          <GridToolbarFilterButton/>
          <GridToolbarDensitySelector/>
          <GridToolbarExport/>
        </GridToolbarContainer>
    );
  }

  return (
      <DataGrid
          {...props}
          style={{height: "87vh"}}
          pagination
          checkboxSelection
          components={{
            NoResultsOverlay: () => CustomNoRowsOverlay(
                {message: `No ${localVar} records found. Try modifying the filter.`}),
            NoRowsOverlay: () => CustomNoRowsOverlay(
                {message: `No ${localVar} records found. Try modifying the date range.`}),
            Toolbar: CustomToolbar,
          }}
          rowsPerPageOptions={[25, 50, 100]}
          columnVisibilityModel={columnModel}
          onColumnVisibilityModelChange={handleColumnChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterChange}
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
      />
  );
}
