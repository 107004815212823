import {useEffect, useState} from "react";
import {useCollectionData} from 'react-firebase-hooks/firestore';
import {useAuthState} from "react-firebase-hooks/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {Button, Chip} from "@mui/material";
import {auth, database} from "../components/firebaseConfig";
import {useAuth} from "../contexts/CustomAuthProvider";
import {
  decrementCreditByOne,
  facilityRequirePayment,
  patientHasAntigenCredit,
  patientHasPCRCredit
} from "../utils/Utils";
import CustomDataGrid from "../components/DataGrid/CustomDataGrid";
import {
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where
} from "firebase/firestore";
import {firestoreDataConverter} from "../const/firestoreDataConverter";
import {useClientIP} from "../hooks/useClientIP";
import Page from "../components/Page";

export default function CheckedIn() {
  const sweetAlert = withReactContent(Swal);
  const {userData} = useAuth();
  const [currentUser] = useAuthState(auth);
  const [clientIP] = useClientIP();
  const [defaultFacilityInfo, setDefaultFacilityInfo] = useState({});
  const defaultFacility = userData?.preferences?.defaultFacility;
  const [data, loading] = useCollectionData(query(database.checkedIn,
      where("facilityId", "==", defaultFacility ?? ""),
      orderBy("timestamp", "desc")
  ).withConverter(firestoreDataConverter));

  useEffect(() => {
    (async () => {
      //load default facility:
      if (userData?.preferences?.defaultFacility === undefined) {
        return;
      }
      getDoc(doc(database.facilities,
          userData?.preferences?.defaultFacility)).then(
          doc => {
            setDefaultFacilityInfo(doc.data());
          });
    })();
  }, [userData]);

  const textPaymentLink = (currentPatientData, facilityId) => {
    addDoc(database.sendSMS, {
      "to": currentPatientData.phone,
      "body": `LabPort🧪: ${currentPatientData.fname}, here is the requested payment link for testing https://register.labport.app/cart?pid=${currentPatientData.id}&site=${facilityId}`
    }).then(() => {
      sweetAlert.fire({
        title: <p>Payment link was queued to be sent.</p>,
        icon: "success",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    }).catch((e) => {
      sweetAlert.fire({
        title: <p>{JSON.stringify(e)}</p>,
        icon: "error",
        timer: 3000,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });
    })
  }

  const balanceColumn = () => {
    return {
      field: 'Balance',
      sortable: false,
      headerName: 'Balance', width: 110, headerAlign: 'left',
      renderCell: (data) => {
        if (data.row?.patient?.credit && (data.row.patient.credit.antigen
            || data.row.patient.credit.pcr)) {
          return (
              <div style={{display: "flex", flexDirection: "column"}}>
                {data.row.patient.credit.antigen > 0 ? <Chip size={"small"}
                                                             color={"primary"}
                                                             label={`Antigen:${data.row.patient.credit.antigen}`}/>
                    :
                    <Button size={"small"} onClick={() => {
                      textPaymentLink(data.row.patient, data.row.facilityId)
                    }}>Txt pay link</Button>
                }
                {data.row.patient.credit.pcr > 0 ? <Chip size={"small"}
                                                         color={"warning"}
                                                         label={`PCR:${data.row.patient.credit.pcr}`}/>
                    :
                    <Button size={"small"} onClick={() => {
                      textPaymentLink(data.row.patient, data.row.facilityId)
                    }}>Txt pay link</Button>}
              </div>
          )
        }
        return <Button size={"small"} onClick={() => {
          textPaymentLink(data.row.patient, data.row.facilityId)
        }}>Txt pay link</Button>
      }
    }
  }

  const updateValue = (documentId, object, key, value, showAlert) => {
    return setDoc(doc(database.checkedIn, documentId),
        {[object]: {[key]: value}},
        {merge: true}).then(() => {
      if (showAlert) {
        sweetAlert.fire({
          title: <p>Saved {key}</p>,
          icon: "success",
          toast: true,
          position: 'bottom-end',
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });
      }
    }).catch((e) => {
      sweetAlert.fire({
        title: <p>Could Not save {key}</p>,
        icon: "error",
        toast: true,
        text: e,
        position: 'bottom-end',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
    })
  };

  const deleteCheckin = (checkInId, type) => {
    deleteDoc(doc(database.checkedIn, checkInId)).then(() => {
      if (type === "deletebtn") {
        sweetAlert.fire({
          title: <p>Deleted Check In</p>,
          icon: "success",
          toast: true,
          position: 'bottom-end',
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });
      }
    }).catch((e) => {
      sweetAlert.fire({
        title: <p>Could Not delete Check In</p>,
        icon: "error",
        toast: true,
        text: e,
        position: 'bottom-end',
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
    })
  };

  const columns = [
    //headers [ date time of check in, DOB, first name, last name, ID photo if any, insurance photo if any, input barcode field, DX, dr's signature, submit, delete  ],
    {
      field: 'id',
      headerName: 'Check In ID',
      width: 200,
      headerAlign: 'left',
      valueGetter: (data) => {
        return data.row.id
      },
    },
    {
      field: 'Date',
      headerName: 'Check in time',
      width: 200,
      type: "date",
      headerAlign: 'left',
      valueGetter: (data) => {
        return new Date(data.row.timestamp.seconds * 1000).toLocaleString()
      },
    },
    {
      field: 'dob',
      headerName: 'Date of birth',
      type: 'date',
      width: 140,
      headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.dob
        }
      },
    },
    ...[((defaultFacilityInfo?.settings?.pcr
        || defaultFacilityInfo?.settings?.antigen) && balanceColumn())]
    .filter(v => v),
    {
      field: 'fname',
      headerName: 'First name', width: 130, headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.fname
        }
      },
    },
    {
      field: 'lname',
      headerName: 'Last name', width: 130, headerAlign: 'left',
      valueGetter: (data) => {
        if (data.row.patient) {
          return data.row.patient.lname
        }
      },
    },
    {
      field: 'photoID',
      headerName: 'Photo ID', width: 130, headerAlign: 'left',
      renderCell: () => {
        return <>
          <Button style={{textDecoration: "none", marginRight: "10px"}}
                  variant={"outlined"}
                  color={"primary"}>View ID</Button>
        </>
      }
    },
    {
      field: 'insuranceCard',
      headerName: 'Insurance Card', width: 150, headerAlign: 'left',
      renderCell: () => {
        return <>
          <Button style={{textDecoration: "none", marginRight: "10px"}}
                  variant={"outlined"}
                  color={"primary"}>Insurance</Button>
        </>
      }
    },
    {
      field: 'pcrBarcode',
      headerName: 'PCR Barcode',
      width: 180,
      headerAlign: 'left',
      editable: true,
      valueGetter: (GridCellParams) => {
        return GridCellParams.row.sample?.pcrBarcode || ""
      },
      valueSetter: (params) => {
        updateValue(params.row.id, "sample", "pcrBarcode",
            params.value.toUpperCase(), false)
        return {...params.row}
      },
    },
    {
      field: 'antigenBarcode',
      headerName: 'Antigen Barcode',
      width: 180,
      headerAlign: 'left',
      editable: true,
      valueGetter: (GridCellParams) => {
        return GridCellParams.row.sample?.antigenBarcode || ""
      },
      valueSetter: (params) => {
        if (params.value) {
          updateValue(params.row.id, "sample", "antigenBarcode",
              params.value.toUpperCase() + "A", false)
          return {...params.row}
        } else {
          updateValue(params.row.id, "sample", "antigenBarcode",
              params.value.toUpperCase(), false)
          return {...params.row}
        }
      }
    },
    {
      field: "options",
      headerName: "Options",
      width: 200,
      sortable: false,
      headerAlign: 'center',
      renderCell: (GridCellParams) => {
        const showRow = () => {
          if (!clientIP) {
            sweetAlert.fire({
              title: <p>Could not identify IP address!</p>,
              icon: "error",
              html: <p>Changes were not saved.<br/><br/>
                Please disabled browser extensions and addons that are blocking your
                IP address, which usually include ad-blockers. Using LabPort on a
                different browser might give you better results.
              </p>,
            }).then();
            return;
          }
          const reqPayload = {};
          reqPayload.rowIndex = GridCellParams.rowIndex;
          /*
          1. get elements results
          2. create ReqForm from results
           */
          const antigenBarcode = GridCellParams.row.sample.antigenBarcode;
          const pcrBarcode = GridCellParams.row.sample.pcrBarcode
          //verify if any value is empty
          if (!(antigenBarcode || pcrBarcode)) {
            sweetAlert.fire({
              title: <p>Missing Values</p>,
              text: "could not generate a requisition form",
              icon: "error",
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showCancelButton: false,
              showConfirmButton: false,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });
            return;
          }
          const addedReqFormPromises = []
          if (pcrBarcode) {
            addedReqFormPromises.push(new Promise(function (resolve, reject) {
                  if (facilityRequirePayment(defaultFacilityInfo)
                      && !patientHasPCRCredit(GridCellParams.row.patient,
                          userData?.preferences?.defaultFacility)) {
                    reject("Insufficient credit")
                    sweetAlert.fire({
                      title: <p>Insufficient credit</p>,
                      icon: "error",
                      timer: 3000,
                      toast: true,
                      position: 'bottom-end',
                      showCancelButton: false,
                      showConfirmButton: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                      }
                    })
                    return;
                  }
                  let pcrPayload = {
                    ...GridCellParams.row
                  };
                  pcrPayload.sample = {
                    "collectionTime": serverTimestamp(),
                    "barcode": pcrBarcode.toUpperCase(),
                    "source": "Nasopharyngeal Swab (NS)",
                    "testPanel": "PCR - COVID-19 SARS-COV-2 MOLECULAR TEST"
                  };
                  pcrPayload.author = {};
                  pcrPayload.author.submittedBy = currentUser.uid;
                  pcrPayload.author.ip = clientIP;
                  pcrPayload.result = null;
                  pcrPayload.billing = null;
                  //clean up check in record
                  delete pcrPayload["facilityId"];
                  delete pcrPayload["id"];
                  delete pcrPayload["timestamp"];
                  delete pcrPayload["sample.antigenBarcode"];
                  delete pcrPayload["sample.pcrBarcode"];
                  pcrPayload.facility = {
                    "id": userData?.preferences?.defaultFacility,
                    "name": defaultFacilityInfo?.name,
                    "address": defaultFacilityInfo?.address,
                    "phone": defaultFacilityInfo?.phone,
                    "source": defaultFacilityInfo?.source,
                    "physician": defaultFacilityInfo?.physicians[0]
                  };
                  if (defaultFacilityInfo.lab) {
                    pcrPayload.facility.lab = defaultFacilityInfo.lab
                  }
                  addDoc(database.reqForms, pcrPayload).then(() => {
                    if (facilityRequirePayment(defaultFacilityInfo)) {
                      decrementCreditByOne(GridCellParams.row.patient.id, "pcr")
                    }
                    resolve();
                    sweetAlert.fire({
                      title: <p>PCR Requisition form added!</p>,
                      icon: "success",
                      toast: true,
                      position: 'bottom-end',
                      timer: 3000,
                      showCancelButton: false,
                      showConfirmButton: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                      }
                    }).then(() => {
                    });
                    //delete checkin
                  }).catch(e => {
                    reject();
                    sweetAlert.fire({
                      title: <p>Could not add record.</p>,
                      text: e,
                      icon: "error",
                      didOpen: () => {
                      }
                    }).then(() => {
                    })
                  })
                })
            )
          }
          if (antigenBarcode) {
            addedReqFormPromises.push(new Promise(function (resolve, reject) {
                  if (facilityRequirePayment(defaultFacilityInfo)
                      && !patientHasAntigenCredit(GridCellParams.row.patient,
                          userData?.preferences?.defaultFacility)) {
                    reject("Insufficient credit")
                    sweetAlert.fire({
                      title: <p>Insufficient credit</p>,
                      icon: "error",
                      timer: 3000,
                      toast: true,
                      position: 'bottom-end',
                      showCancelButton: false,
                      showConfirmButton: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                      }
                    })
                    return;
                  }
                  let antigenPayload = {
                    ...GridCellParams.row
                  };
                  antigenPayload.result = null;
                  antigenPayload.billing = null;
                  antigenPayload.sample = {
                    "collectionTime": serverTimestamp(),
                    "barcode": antigenBarcode.toUpperCase(),
                    "source": "Nasopharyngeal Swab (NS)",
                    "testPanel": "ANTIGEN - COVID-19 SARS-COV-2"
                  };
                  antigenPayload.author = {};
                  antigenPayload.author.submittedBy = currentUser.uid;
                  antigenPayload.author.ip = clientIP;
                  //clean up check in record
                  delete antigenPayload["facilityId"];
                  delete antigenPayload["id"];
                  delete antigenPayload["timestamp"];
                  delete antigenPayload["sample.antigenBarcode"];
                  delete antigenPayload["sample.pcrBarcode"];
                  antigenPayload.facility = {
                    "id": userData?.preferences?.defaultFacility,
                    "name": defaultFacilityInfo?.name,
                    "address": defaultFacilityInfo?.address,
                    "phone": defaultFacilityInfo?.phone,
                    "source": defaultFacilityInfo?.source,
                    "physician": defaultFacilityInfo?.physicians[0]
                  };
                  if (defaultFacilityInfo.lab) {
                    antigenPayload.facility.lab = defaultFacilityInfo.lab
                  }
                  addDoc(database.reqForms, antigenPayload).then(() => {
                    if (facilityRequirePayment(defaultFacilityInfo)) {
                      decrementCreditByOne(GridCellParams.row.patient.id, "antigen")
                    }
                    resolve();
                    sweetAlert.fire({
                      title: <p>Antigen Requisition form added!</p>,
                      icon: "success",
                      toast: true,
                      position: 'bottom-end',
                      timer: 3000,
                      showCancelButton: false,
                      showConfirmButton: false,
                      timerProgressBar: true,
                      didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                      }
                    }).then(() => {
                    });
                    //delete checkin
                  }).catch(e => {
                    reject();
                    sweetAlert.fire({
                      title: <p>Could not add record.</p>,
                      text: e,
                      icon: "error",
                      didOpen: () => {
                      }
                    }).then(() => {
                    })
                  })
                })
            )
          }
          Promise.all(addedReqFormPromises).then(() => {
            deleteCheckin(GridCellParams.id)
          })
        };
        return <>
          <Button style={{
            textDecoration: "none",
            marginRight: "10px",
            backgroundColor: "#3ff131",
            color: "#040C0B"
          }} variant={"contained"} color={"primary"}
                  onClick={showRow}>Submit</Button>
          <Button style={{
            textDecoration: "none",
            color: "secondary",
            marginRight: "10px"
          }}
                  variant={"contained"} color={"secondary"} onClick={() => {
            deleteCheckin(GridCellParams.id)
          }}>Delete</Button>
        </>
      }
    },
  ];
  return (
      <Page title={"LabPort | Checked In"}>
        <CustomDataGrid
            localVar={"CheckedIn"}
            columns={columns}
            rows={data ?? []}
            loading={loading}
            disableSelectionOnClick
            initialColumnVisibility={{
              id: false,
              photoID: false,
              insuranceCard: false,
            }}
        />
      </Page>
  );
}
