import {results} from "../const/results";
import {Chip} from "@mui/material";

export const TestResultChip = (props) => {
  const {value} = props;

  switch (value) {
    case results.negative:
      return <Chip style={{color: "#00b60c"}} label={value}/>;
    case results.positive:
      return <Chip style={{color: "#ff615f"}} label={value}/>;
    case results.inconclusive:
      return <Chip style={{color: "#ff7013"}} label={value}/>;
    case results.pending:
      return <Chip style={{color: "#31a1ff"}} label={value}/>;
    default:
      return <Chip style={{color: "#000000"}} label={value}/>;
  }
}
