import {Link, matchPath, useLocation} from 'react-router-dom';
import {Divider, ListItem, ListItemIcon, ListItemText} from '@mui/material';

function NavItem(props) {
  const {item, routeAccess, active, setToolbarTitle} = props;
  const {
    name,
    path,
    icon,
    toolbarTitle,
    navTitle,
    divider,
    isNotRestricted,
  } = item;
  const isActiveRoot = active(path);

  return (
      (isNotRestricted || (routeAccess ?? []).includes(name)) &&
      <>
        <Link
            className={"disableDecoration"}
            to={path}
            onClick={() => {
              setToolbarTitle(toolbarTitle)
            }}
        >
          <ListItem
              button
              selected={isActiveRoot}
          >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            <ListItemText primary={navTitle}/>
          </ListItem>
        </Link>
        {divider && <Divider/>}
      </>
  );
}

export default function NavSection(props) {
  const {navList, routeAccess, setToolbarTitle} = props;
  const {pathname} = useLocation();
  const match = (path) => (path ?
      Boolean(matchPath({path, end: false}, pathname)) : false);

  return (
      <>
        {navList.map((item) => (
            <NavItem
                key={item.name}
                item={item}
                routeAccess={routeAccess}
                active={match}
                setToolbarTitle={setToolbarTitle}
            />
        ))}
      </>
  );
}
