import {useEffect, useState,} from 'react';
import {doc, getDoc} from "firebase/firestore";
import {Box, CircularProgress} from "@mui/material";
import Page from "../components/Page";
import {database} from "../components/firebaseConfig";
import {useAuth} from "../contexts/CustomAuthProvider";
import EditFacilityForm from "../components/EditFacilityForm";

export default function EditFacility() {

  const [facilityData, setFacilityData] = useState(null);
  const {userData} = useAuth();

  useEffect(() => {
    getDoc(doc(database.facilities, userData?.preferences?.defaultFacility))
    .then((facility) => {
      setFacilityData(facility.data());
    });
  }, []);

  return (
      <Page title={"LabPort | Edit Facility"}>
        {facilityData ?
            <EditFacilityForm facilityData={facilityData}/>
            :
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: "center"
            }}>
              <CircularProgress/>
            </Box>
        }
      </Page>
  );
}
