import parsePhoneNumber from "libphonenumber-js";
import QuizIcon from "@mui/icons-material/Quiz";

export const PrettyPhone = (props) => {
  const {phone} = props;

  return (
      <>
        {
          parsePhoneNumber(phone)?.country ?
              <img
                  style={{
                    marginRight: "5px"
                  }}
                  src={
                      "https://flagcdn.com/" +
                      parsePhoneNumber(phone)?.country?.toLowerCase()
                      + ".svg"
                  }
                  width={"30"}
                  alt={parsePhoneNumber(phone).country}
                  title={parsePhoneNumber(phone).country}
              />
              :
              <QuizIcon style={{marginRight: "5px"}} color={"warning"}/>
        }
        {parsePhoneNumber(phone)?.formatInternational() ?? phone}
      </>
  );
}
